import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid, GridActionsCellItem} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import MailIcon from "@mui/icons-material/Mail";
import AppContainer from "../common/AppContainer";
import ConfirmationDialog from "../common/ConfirmationDialog";
import CompanyAccountInvitationDialog from "./CompanyAccountInvitationDialog";
import CompanyAccountSettingsDialog from "./CompanyAccountSettingsDialog";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [],

	// dialogs
	invitationDialogOpen: false,
	updateDialogOpen: false,
	deleteDialogOpen: false,

	// active row element
	activeAccountId: null
};

class CompanyAccountOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyAccountActionSuccessfully && !prevProps.companyAccountActionSuccessfully) {
			if (this.props.companyAccountInvited && !prevProps.companyAccountInvited) {
				this.onCompanyFetchAccountOverviewList();
				this.onUpdateDialogOpen(this.props.companyAccountInvited.id);
			} else {
				this.onCompanyFetchAccountOverviewList();
			}
		}
	}

	render() {
		const busy = this.props.companyBusy &&
			!this.state.updateDialogOpen;

		const dataGridColumns = [
			{
				field: 'email',
				headerName: this.props.t('company.accountEmail'),
				editable: false,
				flex: 1
			},
			{
				field: 'active',
				headerName: this.props.t('company.accountActive'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 70,
				renderCell: (cellValues) => cellValues.row.state === 'REGISTERED' ? <CheckIcon fontSize="small"/> : null
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('company.accountActions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 120,
				align: 'right',
				getActions: (params) => []
					.concat(
						params.row.state === 'INVITED' ? [<GridActionsCellItem
							title={this.props.t('company.accountResendInvitation')}
							label={this.props.t('company.accountResendInvitation')}
							icon={<MailIcon color="primary" fontSize="small"/>}
							onClick={() => {
								this.props.onCompanyAccountResendInvitation(params.id);
							}}
						/>] : []
					).concat([
						<GridActionsCellItem
							title={this.props.t('company.accountSettings')}
							label={this.props.t('company.accountSettings')}
							icon={<SettingsIcon color="primary" fontSize="small"/>}
							onClick={() => {
								this.onUpdateDialogOpen(params.id);
							}}
						/>,
						<GridActionsCellItem
							title={this.props.t('company.accountDelete')}
							label={this.props.t('company.accountDelete')}
							icon={<DeleteIcon color="primary" fontSize="small"/>}
							onClick={() => {
								this.setState({activeAccountId: params.id});
								this.onDeleteDialogOpen();
							}}
						/>,
					]
					)
			},
		];

		return <AppContainer withMenu needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography
					variant="h6">{this.props.t('company.accountOverviewHeader') + ' ' + this.props.sessionInfo?.companyName}</Typography>
				{<ServerErrorComponent serverError={this.props.companyServerError}/>}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onInvitationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={busy}
							id="btn-account-create"
					>
						{this.props.t('company.accountInvite')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-account-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={busy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableColumnFilter
					disableRowSelectionOnClick

					rows={this.props.companyAccountOverviewList}
					rowCount={this.props.companyAccountOverviewCount}
					density="compact"
				/>
			</Paper>

			<CompanyAccountInvitationDialog
				open={this.state.invitationDialogOpen}
				onClose={this.onInvitationDialogClose}
				onInvite={this.onAccountInvite}/>

			<CompanyAccountSettingsDialog
				accountId={this.state.activeAccountId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onAccountUpdateSettings={this.onAccountUpdateSettings}/>

			<ConfirmationDialog
				title={this.props.t('company.accountDelete')}
				confirm={this.props.t('company.accountDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onAccountDelete}/>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, () => this.onCompanyFetchAccountOverviewList());
	}

	onInvitationDialogOpen = () => {
		this.setState({invitationDialogOpen: true});
	}

	onInvitationDialogClose = () => {
		this.setState({invitationDialogOpen: false});
	}

	onAccountInvite = (request) => {
		this.setState({invitationDialogOpen: false}, () => this.props.onCompanyAccountInvite(request));
	}

	onUpdateDialogOpen = (activeAccountId) => {
		this.setState({updateDialogOpen: true, activeAccountId});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onAccountUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onCompanyAccountUpdateSettings(this.state.activeAccountId, settings));
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onAccountDelete = () => {
		const accountId = this.state.activeAccountId;
		this.setState({
			activeAccountId: null,
			deleteDialogOpen: false
		}, () => this.props.onCompanyAccountDelete(accountId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanyFetchAccountOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchAccountOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchAccountOverviewList);
	}

	onCompanyFetchAccountOverviewList = () => {
		this.props.onCompanyFetchAccountOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,

			companyAccountOverviewList: state.company.accountOverviewList,
			companyAccountOverviewCount: state.company.accountOverviewCount,
			companyAccountActionSuccessfully: state.company.accountActionSuccessfully,
			companyAccountInvited: state.company.accountInvited,
		}
	},
	dispatch => {
		return {
			onCompanyFetchAccountOverviewList: (request) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onCompanyAccountInvite: (request) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_INVITE',
					request
				})
			},
			onCompanyAccountResendInvitation: (accountId) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_RESEND_INVITATION',
					accountId
				})
			},
			onCompanyAccountUpdateSettings: (accountId, settings) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_UPDATE_SETTINGS',
					accountId,
					settings,
				})
			},
			onCompanyAccountDelete: (accountId) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_DELETE',
					accountId
				})
			},
		}
	}
)(CompanyAccountOverviewComponent));
