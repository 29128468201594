import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import {DataGridPro as DataGrid, GridActionsCellItem} from "@mui/x-data-grid-pro";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ServerErrorComponent from "../common/ServerErrorComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {formatInTimeZone} from "date-fns-tz";
import {format, isValid} from "date-fns";
import AppContainer from "../common/AppContainer";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../common/ConfirmationDialog";
import VisitInfoDialog from "./VisitInfoDialog";
import VisitRemoteIdSessionCreateDialog from "./VisitRemoteIdSessionCreateDialog";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS
} from "../common/DataGridColumUtil";
import SettingsIcon from "@mui/icons-material/Settings";

const COLUMN_CONFIG_KEY = 'visit-overview';

const MAP_FILTERS = (fieldFilters) => {
	const formatDate = (date) => {
		if (isValid(date)) {
			return format(date, 'yyyy-LL-dd');
		} else {
			return undefined;
		}
	};
	return {
		requestedAtFilter: formatDate(fieldFilters.requestedAt),
		companyFolderId: fieldFilters.companyFolderId,
		docIdSessionState: fieldFilters.docIdSessionState
	};
}

class VisitOverviewComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'requestedAt', sort: 'desc'}],
			fieldFilters: {},

			// columns
			columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['requestedAt', 'state', 'companyFolderName', 'state', 'docIdSessionState', 'phoneNumber', 'creatorEmail']),

			// dialogs
			infoDialogId: null,
			remoteIdSessionCreateDialogOpen: false,
			deleteDialogId: null,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!!this.props.visitRemoteIdSessionCreated && !prevProps.visitRemoteIdSessionCreated) {
			this.setState({
				infoDialogId: this.props.visitRemoteIdSessionCreated.id
			}, this.onVisitFetchOverviewList);
		}

		if (this.props.visitDeleted && !prevProps.visitDeleted) {
			this.onVisitFetchOverviewList();
		}
	}

	render() {
		const busy = this.props.visitBusy && !this.state.infoDialogId;

		const columnConfiguration = this.state.columnConfiguration;

		const allPossibleDataGridColumns = [
			{
				field: 'requestedAt',
				headerName: this.props.t('visit.requestedAt'),
				editable: false,
				optional: false,
				flex: 1,
				valueGetter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'state',
				headerName: this.props.t('visit.state'),
				editable: false,
				optional: false,
				flex: 1,
				valueGetter: (value) => !!value ? this.props.t('visit.state_' + value) : ''
			},
			{
				field: 'companyFolderName',
				headerName: this.props.t('visit.companyFolderName'),
				editable: false,
				optional: true,
				flex: 1,
			},
			{
				field: 'docIdSessionState',
				headerName: this.props.t('visit.docIdSessionState'),
				editable: false,
				optional: true,
				flex: 1,
				valueGetter: (value) => !!value ? this.props.t('visit.docIdSessionState_' + value) : ''
			},
			{
				field: 'phoneNumber',
				headerName: this.props.t('visit.phoneNumber'),
				editable: false,
				optional: true,
				flex: 1
			},
			{
				field: 'creatorEmail',
				headerName: this.props.t('visit.creatorEmail'),
				editable: false,
				optional: true,
				flex: 1
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: '',
				editable: false,
				sortable: false,
				optional: false,
				resizable: false,
				disableColumnMenu: true,
				disableReorder: true,
				width: 100,
				align: 'right',
				getActions: (params) => [
					<GridActionsCellItem
						title={this.props.t('visit.info')}
						label={this.props.t('visit.info')}
						icon={<TroubleshootIcon color="primary" fontSize="small"/>}
						onClick={() => {
							this.onOpenSessionInfoDialog(params.id);
						}}
					/>,
					((disabled) =>
						<GridActionsCellItem
							title={this.props.t('visit.delete')}
							label={this.props.t('visit.delete')}
							disabled={disabled}
							icon={<DeleteIcon color={disabled ? 'disabled' : 'primary'} fontSize="small"/>}
							onClick={() => {
								this.onOpenDeleteDialog(params.id);
							}}
						/>
					)(!params.row.canDelete),
				]
			},
		];

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		return <AppContainer withMenu needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('visit.overviewHeader')}</Typography>
				{!this.state.infoDialogId && <ServerErrorComponent serverError={this.props.visitServerError}/>}
				{!!this.props.visitOverviewInfo?.canCreateRemoteIdSession &&
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
						<Button variant="contained"
								onClick={this.onOpenRemoteIdSessionCreateDialog}
								startIcon={<AddIcon/>}
								sx={{mr: 1}}
								disabled={busy}
								id="btn-remote-id-create-session"
						>
							{this.props.t('visit.createRemoteIdSession')}
						</Button>
					</Box>}
				<Box sx={{mt: 2}}>
					<DataGrid
						autoHeight
						disableColumnSelector
						disableColumnFilter
						disableColumnPinning

						columns={filteredColumns}
						onColumnHeaderClick={this.onColumnHeaderClick}
						onColumnResize={this.onResizeColumn}
						onColumnOrderChange={this.onColumnOrderChange}

						slots={{toolbar: FlexibleToolbar}}
						slotProps={{
							toolbar: {
								filterId: 'input-remote-id-overview-search-text',
								filterValue: this.state.filterValue,
								onChangeFilterValue: this.onFilterValueChange,

								possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
								columns: filteredColumns.map(column => column.field),
								onChangeColumns: this.onChangeExtraColumns,
								onResetColumnsWidth: this.onResetColumnsWidth,

								fieldFilters: [{
									field: 'requestedAt',
									name: this.props.t('visit.requestedAt'),
									mode: 'date',
								}, {
									field: 'companyFolderId',
									name: this.props.t('visit.companyFolderName'),
									mode: 'select',
									options: this.props.visitOverviewInfo?.folders?.map(folder => ({
										value: folder.id,
										name: folder.name
									})) || [],
								}, {
									field: 'docIdSessionState',
									name: this.props.t('visit.docIdSessionState'),
									mode: 'select',
									options: this.props.visitOverviewInfo?.docIdSessionStates?.map(state => ({
										value: state,
										name: this.props.t('visit.docIdSessionState_' + state)
									})) || []
								}],
								fieldFiltersValues: this.state.fieldFilters,
								onChangeFieldFilter: this.onChangeFieldFilter,

								refreshAction: this.onVisitFetchOverviewList,
							},
						}}

						loading={busy}

						pagination
						paginationMode="server"
						paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
						onPaginationModelChange={this.onPaginationModelChange}
						pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

						sortingMode="server"
						sortModel={this.state.sortModel}
						onSortModelChange={this.onSortModelChange}

						disableColumnFilter
						disableRowSelectionOnClick

						rows={this.props.visitOverviewList}
						rowCount={this.props.visitOverviewCount}
						density="compact"/>
				</Box>
			</Paper>

			<VisitRemoteIdSessionCreateDialog
				open={this.state.remoteIdSessionCreateDialogOpen}
				onClose={this.onCloseRemoteIdSessionCreateDialog}
				onCreate={this.onVisitRemoteIdSessionCreate}
				folders={this.props.visitOverviewInfo?.folders}
			/>

			<VisitInfoDialog
				open={!!this.state.infoDialogId}
				id={this.state.infoDialogId}
				onClose={this.onCloseSessionInfoDialog}
			/>

			<ConfirmationDialog
				title={this.props.t('visit.delete')}
				confirm={this.props.t('visit.deleteConfirm')}
				open={!!this.state.deleteDialogId}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onVisitDelete}/>

		</AppContainer>;
	}

	onSessionCreated = (sessionInfo) => {
		this.props.onVisitFetchOverviewInfo();
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, this.onVisitFetchOverviewList);
	}

	onOpenRemoteIdSessionCreateDialog = () => {
		this.setState({
			remoteIdSessionCreateDialogOpen: true
		})
	}

	onCloseRemoteIdSessionCreateDialog = () => {
		this.setState({
			remoteIdSessionCreateDialogOpen: false
		})
	}

	onVisitRemoteIdSessionCreate = (request) => {
		this.setState({remoteIdSessionCreateDialogOpen: false}, () => this.props.onVisitRemoteIdSessionCreate(request));
	}

	onOpenSessionInfoDialog = (infoDialogId) => {
		this.setState({
			infoDialogId,
		});
	}

	onCloseSessionInfoDialog = () => {
		this.setState({
			infoDialogId: null,
		}, this.onVisitFetchOverviewList);
	}

	onOpenDeleteDialog = (deleteDialogId) => {
		this.setState({
			deleteDialogId,
		});
	}

	onCloseDeleteDialog = () => {
		this.setState({
			deleteDialogId: null,
		});
	}

	onVisitDelete = () => {
		const id = this.state.deleteDialogId;
		this.setState({
			deleteDialogId: null
		}, () => this.props.onVisitDelete(id))
	}

	onColumnHeaderClick = (params, event) => {
		// capture datagrid header 'select all' event
		if (params.field === '__check__') {
			this.setState({showSelectAllNotice: event.target.checked})
		}
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onVisitFetchOverviewList)
	}

	onChangeFieldFilter = (field, value) => {
		const fieldFilters = {...this.state.fieldFilters};
		if (!value) {
			delete fieldFilters[field];
		} else {
			fieldFilters[field] = value;
		}
		this.setState({
			fieldFilters
		}, this.onVisitFetchOverviewList);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onVisitFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onVisitFetchOverviewList);
	}

	onVisitFetchOverviewList = () => {
		this.props.onVisitFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			...MAP_FILTERS(this.state.fieldFilters),
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			visitBusy: state.visit.busy,
			visitServerError: state.visit.serverError,
			visitOverviewInfo: state.visit.overviewInfo,
			visitOverviewList: state.visit.overviewList,
			visitOverviewCount: state.visit.overviewCount,
			visitDeleted: state.visit.deleted,
			visitRemoteIdSessionCreated: state.visit.remoteIdSessionCreated
		}
	},
	dispatch => {
		return {
			onVisitFetchOverviewInfo: () => {
				dispatch({
					type: 'VISIT_FETCH_OVERVIEW_INFO',
				});
			},
			onVisitFetchOverviewList: (request) => {
				dispatch({
					type: 'VISIT_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onVisitRemoteIdSessionCreate: (request) => {
				dispatch({
					type: 'VISIT_REMOTE_ID_SESSION_CREATE',
					request
				});
			},
			onVisitDelete: (id) => {
				dispatch({
					type: 'VISIT_DELETE',
					id
				});
			},
		}
	}
)(VisitOverviewComponent));
