import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid, GridActionsCellItem} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AppContainer from "../common/AppContainer";
import ConfirmationDialog from "../common/ConfirmationDialog";

import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import FolderCreationDialog from "./FolderCreationDialog";
import FolderSettingsDialog from "./FolderSettingsDialog";
import FolderPermissionsDialog from "./FolderPermissionsDialog";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [],

	// dialogs
	creationDialogOpen: false,
	updateDialogOpen: false,
	permissionDialogOpen: false,
	deleteDialogOpen: false,

	// active row element
	activeFolderId: null
};

class FolderOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.folderActionSuccessfully && !prevProps.folderActionSuccessfully) {
			this.onFolderFetchOverviewList();
		}
	}

	render() {
		const busy = this.props.folderBusy && !this.state.updateDialogOpen && !this.state.permissionDialogOpen;
		const admin = this.props.sessionInfo?.companyFolderAdmin || false;

		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('folder.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'description',
				headerName: this.props.t('folder.description'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('folder.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 120,
				align: 'right',
				getActions: (params) => [
					<GridActionsCellItem
						title={this.props.t('folder.settings')}
						label={this.props.t('folder.settings')}
						icon={<SettingsIcon color="primary" fontSize="small"/>}
						onClick={() => this.onUpdateDialogOpen(params.id)}
					/>,
					<GridActionsCellItem
						title={this.props.t('folder.permissions')}
						label={this.props.t('folder.permissions')}
						icon={<ManageAccountsIcon color="primary" fontSize="small"/>}
						onClick={() => {
							this.setState({activeFolderId: params.id});
							this.onPermissionDialogOpen();
						}}
					/>,
					((disabled) =>
						<GridActionsCellItem
							title={this.props.t('folder.delete')}
							label={this.props.t('folder.delete')}
							disabled={disabled}
							icon={<DeleteIcon color={disabled ? 'disabled' : 'primary'} fontSize="small"/>}
							onClick={() => {
								this.setState({activeFolderId: params.id});
								this.onDeleteDialogOpen();
							}}
						/>
					)(params.row.defaultFolder || !admin),
				]
			}
		];

		return <AppContainer withMenu needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography
					variant="h6">{this.props.t('folder.overviewHeader') + ' ' + this.props.sessionInfo?.companyName}</Typography>
				{<ServerErrorComponent serverError={this.props.folderServerError}/>}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={busy || !admin}
							id="btn-folder-create"
					>
						{this.props.t('folder.create')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-folder-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={busy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableColumnFilter
					disableRowSelectionOnClick

					rows={this.props.folderOverviewList}
					rowCount={this.props.folderOverviewCount}
					density="compact"
				/>
			</Paper>

			<FolderCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onFolderCreate}/>

			<FolderSettingsDialog
				folderId={this.state.activeFolderId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onFolderUpdateSettings={this.onFolderUpdateSettings}/>

			<FolderPermissionsDialog
				folderId={this.state.activeFolderId}
				open={this.state.permissionDialogOpen}
				onClose={this.onPermissionDialogClose}
				onFolderUpdatePermissions={this.onFolderUpdatePermissions}/>

			<ConfirmationDialog
				title={this.props.t('folder.delete')}
				confirm={this.props.t('folder.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onFolderDelete}/>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, this.onFolderFetchOverviewList);
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onFolderCreate = (request) => {
		this.setState({creationDialogOpen: false}, () => this.props.onFolderCreate(request));
	}

	onUpdateDialogOpen = (activeFolderId) => {
		this.setState({updateDialogOpen: true, activeFolderId});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onFolderUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onFolderUpdateSettings(this.state.activeFolderId, settings));
	}

	onPermissionDialogOpen = () => {
		this.setState({permissionDialogOpen: true});
	}

	onPermissionDialogClose = () => {
		this.setState({permissionDialogOpen: false});
	}

	onFolderUpdatePermissions = (settings) => {
		this.setState({permissionDialogOpen: false}, () => this.props.onFolderUpdatePermissions(this.state.activeFolderId, settings));
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onFolderDelete = () => {
		const folderId = this.state.activeFolderId;
		this.setState({activeFolderId: null, deleteDialogOpen: false}, () => this.props.onFolderDelete(folderId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onFolderFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFolderFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFolderFetchOverviewList);
	}

	onFolderFetchOverviewList = () => {
		this.props.onFolderFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			folderBusy: state.folder.busy,
			folderServerError: state.folder.serverError,

			folderOverviewList: state.folder.overviewList,
			folderOverviewCount: state.folder.overviewCount,
			folderActionSuccessfully: state.folder.actionSuccessfully,
			folderCreated: state.folder.created,
		}
	},
	dispatch => {
		return {
			onFolderFetchOverviewList: (request) => {
				dispatch({
					type: 'FOLDER_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onFolderCreate: (request) => {
				dispatch({
					type: 'FOLDER_CREATE',
					request
				})
			},
			onFolderUpdateSettings: (folderId, settings) => {
				dispatch({
					type: 'FOLDER_UPDATE_SETTINGS',
					folderId,
					settings,
				})
			},
			onFolderUpdatePermissions: (folderId, permissions) => {
				dispatch({
					type: 'FOLDER_UPDATE_PERMISSIONS',
					folderId,
					permissions
				});
			},
			onFolderDelete: (folderId) => {
				dispatch({
					type: 'FOLDER_DELETE',
					folderId
				})
			},
		}
	}
)(FolderOverviewComponent));