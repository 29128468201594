import {useLocation, useNavigate, useParams} from "react-router";
import {useEffect} from "react";

export const withRouter = (Component) => {
	return (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		return (
			<Component
				{...props}
				router={{location, navigate, params}}
			/>
		);
	};
}
export const Redirect = ({to}) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(to);
	}, []);
}