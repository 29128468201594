import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import SessionProfileGeneralComponent from "./SessionProfileGeneralComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SessionProfileSecurityComponent from "./SessionProfileSecurityComponent";

const TAB_GENERAL = { key: 'GENERAL', translateText: (props) => props.t('session.profileTab_GENERAL') };
const TAB_SECURITY = { key: 'SECURITY', translateText: (props) => props.t('session.profileTab_SECURITY') }
const TABS = [TAB_GENERAL, TAB_SECURITY]

class SessionProfileComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: TABS.at(0),
			requestedTab: TABS.at(0),
			shouldSave: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// save action has completed (succeeded/failed)
		if ((prevProps.sessionPendingChanges && !this.props.sessionPendingChanges) ||
			(!prevProps.sessionServerError && !!this.props.sessionServerError)) {
			this.setState({shouldSave: false});
		}
	}

	render() {
		return <AppContainer
			withMenu
			needsSession
			hasPendingChanges={this.props.sessionPendingChanges}
			saveIsBusy={this.props.sessionBusy}
			saveHasError={!!this.props.sessionServerError}
			onSave={this.onSave}
			customNavigation={this.state.tab !== this.state.requestedTab}
			onCustomNavigationComplete={this.onCustomNavigationComplete}
			onCustomNavigationCanceled={this.onCustomNavigationCanceled}
		>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
					{TABS.map(tab => <Tab key={tab.key} value={tab} label={tab.translateText(this.props)}></Tab>)}
				</Tabs>
				<Box sx={{mt: 2}}>
					{this.renderCurrentTab()}
				</Box>
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case TAB_GENERAL:
				return <SessionProfileGeneralComponent shouldSave={this.state.shouldSave} />;
			case TAB_SECURITY:
				return <SessionProfileSecurityComponent />;
		}
	}

	onChangeTab = (e, requestedTab) => {
		this.setState({requestedTab});
	}

	onCustomNavigationComplete = () => {
		this.setState({tab: this.state.requestedTab});
	}

	onCustomNavigationCanceled = () => {
		this.setState({requestedTab: this.state.tab});
	}

	onSave = () => {
		this.setState({shouldSave: true});
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionServerError: state.session.serverError,
			sessionPendingChanges: state.session.pendingChanges,
			sessionBusy: state.session.busy,
		}
	},
	dispatch => {
		return {}
	}
)(SessionProfileComponent));
