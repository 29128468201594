import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChangePasswordComponent from "./ChangePasswordComponent";

class SessionProfileSecurityComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			deleteAccountDialogOpen: false,

			password: '',
			passwordConfirmation: '',
			changedPassword: false,
		}
	}

	componentDidMount() {
		this.props.onSessionClearPendingChanges();
	}

	render() {
		const busy = this.props.sessionBusy && !this.state.deleteAccountDialogOpen;

		return <>
			<Typography sx={{fontWeight: 700}}>{this.props.t('session.profileChangePasswordTitle')}</Typography>
			<Box sx={{mt: 2, ml: 1}}>
				<ChangePasswordComponent
					password={this.state.password}
					onPasswordChange={this.onPasswordChange}
					passwordConfirmation={this.state.passwordConfirmation}
					onPasswordConfirmationChange={this.onPasswordConfirmationChange}
				/>
				{this.state.changedPassword && <ServerErrorComponent serverError={this.props.sessionServerError}/>}
				<Button disabled={busy}
						variant="contained"
						onClick={this.onSessionPasswordSet}
				>
					{this.props.t('session.profileChangePasswordAction')}
				</Button>
			</Box>
			<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('session.profileDeleteAccountTitle')}</Typography>
			<Box>
				<Button variant="contained" disabled={busy} sx={{mt: 1, ml: 1}}
						onClick={this.onDeleteAccountOpenDialog}>{this.props.t('session.profileDeleteAccountAction')}</Button>
			</Box>
			<ConfirmationDialog
				open={this.state.deleteAccountDialogOpen}
				title={this.props.t('session.profileDeleteAccountTitle')}
				confirm={this.props.t('session.profileDeleteAccountConfirm')}
				onClose={this.onDeleteAccountCloseDialog}
				onConfirm={this.onSessionRemoveAccount}
			/>
		</>
	}

	onPasswordChange = (e) => {
		this.setState({password: e.target.value});
	}

	onPasswordConfirmationChange = (e) => {
		this.setState({passwordConfirmation: e.target.value});
	}

	onSessionPasswordSet = () => {
		const {password, passwordConfirmation} = this.state;
		this.setState({changedPassword: true},
			() => this.props.onSessionPasswordSet(password, passwordConfirmation));
	}

	onDeleteAccountOpenDialog = () => {
		this.setState({deleteAccountDialogOpen: true});
	}

	onDeleteAccountCloseDialog = () => {
		this.setState({deleteAccountDialogOpen: false});
	}

	onSessionRemoveAccount = () => {
		this.setState({deleteAccountDialogOpen: false, changedPassword: false}, this.props.onSessionRemoveAccount);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionBusy: state.session.busy,
			sessionInfo: state.session.info,
			sessionProfile: state.session.profile,
			sessionServerError: state.session.serverError,
		}
	},
	dispatch => {
		return {
			onSessionPasswordSet: (password, passwordConfirmation) => {
				dispatch({
					type: 'SESSION_PASSWORD_SET',
					password,
					passwordConfirmation
				})
			},
			onSessionRemoveAccount: () => {
				dispatch({
					type: 'SESSION_REMOVE_ACCOUNT'
				})
			},
			onSessionClearPendingChanges: () => {
				dispatch({
					type: 'SESSION_CLEAR_PENDING_CHANGES'
				})
			}
		}
	}
)(SessionProfileSecurityComponent));
