import React, {Component, useCallback, useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";

const CompanyItsmeConfigDialog = ({open, possibleItsmeAttributeTypes, itsmeConfig, onSubmit, onClose}) => {

	const {t} = useTranslation();

	const [name, setName] = useState(itsmeConfig?.name || '');
	const [clientId, setClientId] = useState(itsmeConfig?.clientId || '');
	const [serviceCode, setServiceCode] = useState(itsmeConfig?.serviceCode || '');
	const [attributeTypes, setAttributeTypes] = useState(itsmeConfig?.attributeTypes || []);

	const onChangeName = useCallback((e) => {
		setName(e.target.value)
	}, []);

	const onChangeClientId = useCallback((e) => {
		setClientId(e.target.value)
	}, []);

	const onChangeServiceCode = useCallback((e) => {
		setServiceCode(e.target.value)
	}, []);

	const onChangeAttributeTypes = useCallback((e) => {
		const value = e.target.value;
		setAttributeTypes(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	}, []);

	const onLocalClose = useCallback((e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}, [onClose]);

	const onLocalSubmit = useCallback(() => {
		onSubmit({
			...itsmeConfig,
			name,
			clientId,
			serviceCode,
			attributeTypes
		});
	}, [onSubmit, name, clientId, serviceCode, attributeTypes]);

	const onKeyUp = useCallback((e) => {
		if (e.key === 'Enter') {
			onLocalSubmit();
		}
	}, [onLocalSubmit]);

	useEffect(() => {
		setName(itsmeConfig?.name || '')
		setClientId(itsmeConfig?.clientId || '')
		setServiceCode(itsmeConfig?.serviceCode || '')
		setAttributeTypes(itsmeConfig?.attributeTypes || [])
	}, [itsmeConfig])

	return <Dialog
		open={open}
		onClose={onLocalClose}
		onKeyUp={onKeyUp}
		fullWidth
		maxWidth="md"
	>
		<DialogTitle>{t('company.itsmeConfigEdit')}</DialogTitle>
		<DialogContent>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 3, pt: 1}}>
				<TextField
					variant="outlined"
					label={t('company.itsmeConfigName')}
					value={name}
					onChange={onChangeName}
					autoComplete="off"
					fullWidth
					size="small"
				/>
				<TextField
					variant="outlined"
					label={t('company.itsmeClientId')}
					value={clientId}
					onChange={onChangeClientId}
					autoComplete="off"
					fullWidth
					size="small"
				/>
				<TextField
					variant="outlined"
					label={t('company.itsmeServiceCode')}
					value={serviceCode}
					onChange={onChangeServiceCode}
					autoComplete="off"
					fullWidth
					size="small"
				/>
				<FormControl
					fullWidth
					size="small"
				>
					<InputLabel id="itsme-config-attribute-types-label">{t('company.itsmeAttributeTypes')}</InputLabel>
					<Select
						variant="outlined"
						labelId="itsme-config-attribute-types-label"
						id="itsme-config-attribute-types"
						multiple
						value={attributeTypes}
						onChange={onChangeAttributeTypes}
						input={<OutlinedInput label={t('company.itsmeAttributeTypes')} />}
						renderValue={(selected) => selected.join(', ')}
					>
						{possibleItsmeAttributeTypes.map((type) => (
							<MenuItem key={type} value={type}>
								<Checkbox checked={attributeTypes.indexOf(type) > -1} />
								<ListItemText primary={type} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={onClose}
				id="btn-itsme-config-edit-cancel"
			>
				{t('cancel')}
			</Button>
			<Button
				variant="contained"
				onClick={onLocalSubmit}
				id="btn-itsme-config-edit-save"
			>
				{t('save')}
			</Button>
		</DialogActions>
	</Dialog>;
}

class CompanyAdminSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showSecret: false,
			itsmeConfig: null,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onCompanyFetchAdminSettings(this.props.companyId);
		}
	}

	render() {
		const companyAdminSettings = this.props.companyAdminSettings;

		const itsmeConfigs = [...(companyAdminSettings?.itsmeConfigs || [])].sort((a, b) => {
			if (a.id < 0 && b.id > 0) return 1;
			if (a.id > 0 && b.id < 0) return -1;
			return a.id - b.id;
		})

		const itsmeConfigColumns = [
			{
				field: 'name',
				headerName: this.props.t('company.itsmeConfigName'),
				editable: false,
				sortable: false,
				flex: 1
			},
			{
				field: 'defaultConfig',
				headerName: this.props.t('company.itsmeConfigDefault'),
				editable: false,
				sortable: false,
				width: 120,
				align: 'center',
				renderCell: (cellValues) => cellValues.row.defaultConfig ? <CheckIcon fontSize="small" sx={{mt: '7px'}} /> : null
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: '',
				editable: false,
				sortable: false,
				width: 90,
				align: 'center',
				getActions: (params) => [
					<GridActionsCellItem
						title={this.props.t('company.itsmeConfigEdit')}
						label={this.props.t('company.itsmeConfigEdit')}
						icon={<SettingsIcon color="primary" fontSize="small"/>}
						onClick={() => this.onItsmeConfigEdit({...params.row})}
					/>,
					((disabled) =>
						<GridActionsCellItem
							disabled={disabled}
							title={this.props.t('company.itsmeConfigDelete')}
							label={this.props.t('company.itsmeConfigDelete')}
							icon={<DeleteIcon color={disabled ? 'disabled' : 'primary'} fontSize="small"/>}
							onClick={() => this.onItsmeConfigDelete(params.id)}
						/>
					)(params.row.defaultConfig)
				]
			},
		];

		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.adminSettingsHeader')}</DialogTitle>
			{!companyAdminSettings && <DialogContent><LoadingComponent /></DialogContent>}
			{companyAdminSettings && <DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.nameHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.name')}
								required
								value={companyAdminSettings.name}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								size="small"
							/>
						</StepContent>
					</Step>

					{this.props.sessionInfo.applicationAdmin && <Step active>
						<StepLabel>{this.props.t('company.itsmeHeader')}</StepLabel>
						<StepContent>
							<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
								<DataGrid
									hideFooter
									disableColumnSelector
									columns={itsmeConfigColumns}

									disableColumnFilter
									disableRowSelectionOnClick

									rows={itsmeConfigs}

									pagination
									paginationModel={{page: 0, pageSize: 5}}
									pageSizeOptions={[5]}

									density="compact"
									autoHeight
								/>
								<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
									<Button
										variant="contained"
										onClick={this.onItsmeConfigCreate}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-itsme-config-create"
										size="small"
										disabled={companyAdminSettings.itsmeConfigs.length >= 5}
									>
										{this.props.t('company.itsmeConfigCreate')}
									</Button>
								</Box>
							</Box>
						</StepContent>
					</Step>}

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsDomain')}</StepLabel>
						<StepContent>
							<Box>
								<FormControl fullWidth>
									<RadioGroup
										value={this.determineUrlDomainTypeRadioGroupValue(companyAdminSettings)}
										onChange={this.onChangeUrlDomainType}
									>
										<FormControlLabel value="NONE" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainNone')}/>
										<FormControlLabel value="SUBDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainSubdomain')}/>
										{companyAdminSettings.urlSubdomainEnabled && <Box sx={{ml: 2, width: '100%'}}>
											<Alert severity="info" sx={{mb: 1}}>{this.props.t('company.adminSettingsDomainSubdomainExample') + ` https://${companyAdminSettings.urlSubdomain}.dias.dioss.com`}</Alert>

											<TextField
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainSubdomainValidation')}
												label={this.props.t('company.adminSettingsDomainSubdomain')}
												value={companyAdminSettings.urlSubdomain || ''}
												onChange={this.onChangeUrlSubdomain}
											/>

										</Box>}

										<FormControlLabel value="CUSTOMDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainCustomDomain')}/>
										{companyAdminSettings.urlCustomDomainEnabled && <Box sx={{ml: 2}}>
											<TextField
												InputProps={{
													startAdornment: <InputAdornment position="start" sx={{mr: 0}}>https://</InputAdornment>,
												}}
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainCustomDomainValidation')}
												label={this.props.t('company.adminSettingsDomainCustomDomain')}
												value={companyAdminSettings.urlCustomDomain || ''}
												onChange={this.onChangeUrlCustomDomain}
											/>
										</Box>}
									</RadioGroup>
								</FormControl>

								{(companyAdminSettings.urlSubdomainEnabled || companyAdminSettings.urlCustomDomainEnabled) &&  <Box>
									<Typography sx={{mt: 2}}>{this.props.t('company.adminSettingsWhitelistedFrameDomainsDescription')}</Typography>

									<TextField
										fullWidth
										label={this.props.t('company.adminSettingsWhitelistedFrameDomains')}
										helperText={this.props.t('company.adminSettingsWhitelistedFrameDomainsHelperText')}
										value={companyAdminSettings.whitelistedFrameDomainsDelimited}
										onChange={this.onChangeWhitelistedFrameDomainsDelimited}
										autoComplete="off"
										sx={{mt: 0.5}}
									/>
								</Box>}
							</Box>
						</StepContent>
					</Step>

					{!companyAdminSettings.childCompany && <Step active>
						<StepLabel>{this.props.t('company.adminSettingsCanManageChildCompanies')}</StepLabel>
						<StepContent>
							<Box sx={{ml: 1}}>
								<FormControlLabel
									control={
										<Checkbox checked={companyAdminSettings.canManageChildCompanies}
												  onChange={(e, enabled) => this.onChangeCanManageChildCompanies(enabled)}
										/>
									}
									label={this.props.t('company.adminSettingsCanManageChildCompaniesDescription')}
									sx={{p: 0}}
								/>
							</Box>
						</StepContent>
					</Step>}

					<Step active>
						<StepLabel>{this.props.t('company.contactEmailHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.contactEmail')}
								value={companyAdminSettings.contactEmail}
								onChange={this.onChangeContactEmail}
								autoComplete="off"
								fullWidth
								size="small"
							/>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.adminSettingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>}

			<DialogActions>
				<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!companyAdminSettings || !this.canContinue()} onClick={this.onUpdate}
						id="btn-settings-save">
					{this.props.t('save')}
				</Button>
			</DialogActions>

			<CompanyItsmeConfigDialog
				open={!!this.state.itsmeConfig}
				possibleItsmeAttributeTypes={this.props.companyAdminSettings?.possibleItsmeAttributeTypes || []}
				itsmeConfig={this.state.itsmeConfig}
				onClose={this.onItsmeConfigClose}
				onSubmit={this.onItsmeConfigUpdate}
			/>
		</Dialog>
	}

	canContinue = () => {
		if (!this.props.companyAdminSettings) {
			return false;
		}

		const {name} = this.props.companyAdminSettings;
		return !!name.trim();
	}

	onChangeName = (e) => {
		this.props.onCompanyChangeAdminSetting('name', e.target.value);
	}

	onItsmeConfigEdit = (itsmeConfig) => {
		this.setState({itsmeConfig})
	}

	onItsmeConfigDelete = (id) => {
		const itsmeConfigs = this.props.companyAdminSettings.itsmeConfigs
			.filter(config => config.id !== id);

		this.props.onCompanyChangeAdminSetting('itsmeConfigs', itsmeConfigs);
	}

	onItsmeConfigCreate = () => {
		// gives an id starting from -1, -2, -3, ...
		const id = this.props.companyAdminSettings.itsmeConfigs
			.map(config => config.id)
			.reduce((a, b) => a <= b ? a : b, 0) - 1;

		this.setState({itsmeConfig: {
				id,
				name: 'New config',
				clientId: '',
				oauth2Scopes: '',
				oidcClaims: '',
		}});
	}

	onItsmeConfigClose = () => {
		this.setState({itsmeConfig: null});
	}

	onItsmeConfigUpdate = (itsmeConfig) => {
		const itsmeConfigs = this.props.companyAdminSettings.itsmeConfigs
			.filter(config => config.id !== itsmeConfig.id)
		;

		itsmeConfigs.push(itsmeConfig);

		this.setState({itsmeConfig: null},
			() => this.props.onCompanyChangeAdminSetting('itsmeConfigs', itsmeConfigs));
	}

	determineUrlDomainTypeRadioGroupValue(companyAdminSettings) {
		if (!companyAdminSettings.urlSubdomainEnabled && !companyAdminSettings.urlCustomDomainEnabled) {
			return 'NONE';
		} else if (companyAdminSettings.urlSubdomainEnabled) {
			return 'SUBDOMAIN';
		} else if (companyAdminSettings.urlCustomDomainEnabled) {
			return 'CUSTOMDOMAIN';
		}
	}

	onChangeUrlDomainType = (e) => {
		if (e.target.value === 'NONE') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		} else if (e.target.value === 'SUBDOMAIN') {
			const proposedName = this.props.companyAdminSettings.name?.toLowerCase()?.replaceAll(/[^a-z0-9-_]/g, '');
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', proposedName);

		} else if (e.target.value === 'CUSTOMDOMAIN') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		}
	}

	onChangeUrlSubdomain = (e) => {
		if (/^[a-z0-9_-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlSubdomain', e.target.value);
		}
	}

	onChangeUrlCustomDomain = (e) => {
		if (/^[a-z0-9._-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', e.target.value);
		}
	}

	onChangeWhitelistedFrameDomainsDelimited = (e) => {
		this.props.onCompanyChangeAdminSetting('whitelistedFrameDomainsDelimited', e.target.value);
	}

	onChangeCanManageChildCompanies = (enabled) => {
		this.props.onCompanyChangeAdminSetting('canManageChildCompanies', enabled);
	}

	onChangeContactEmail = (e) => {
		this.props.onCompanyChangeAdminSetting('contactEmail', e.target.value);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue() && !this.state.itsmeConfig) {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onUpdateAdminSettings(this.props.companyAdminSettings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			companyBusy: state.company.busy,
			companyAdminSettings: state.company.adminSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchAdminSettings: (companyId) => {
				dispatch({
					type: 'COMPANY_FETCH_ADMIN_SETTINGS',
					companyId
				});
			},
			onCompanyChangeAdminSetting: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_ADMIN_SETTING',
					key,
					value
				});
			}
		}
	}
)(CompanyAdminSettingsDialog));
