import {Trans, useTranslation} from "react-i18next";
import {Box, Container, Link, Paper, Typography} from "@mui/material";
import AppContainer from "../common/AppContainer";
import {useSelector} from "react-redux";
import React from "react";

export default () => {

	const {t} = useTranslation();
	const version = useSelector((state) => state.session?.info?.applicationVersion?.version);

	return <AppContainer withMenu needsSession>
		<Paper variant="outlined" sx={{
			p: {xs: 2, md: 3},
			display: 'flex',
			flexDirection: 'column',
			gap: 2
		}}>
			<Box>
				<Typography variant="h6">{t('info.termsAndConditionsTitle')}</Typography>
				<Typography>
					<Trans i18nKey="info.termsAndConditions" t={t}
						   components={[<Link target="_blank" href={t('info.termsAndConditionsLink')}>_</Link>]}/>
				</Typography>
			</Box>

			<Box>
				<Typography variant="h6">{t('info.privacyStatementTitle')}</Typography>
				<Typography>
					<Trans i18nKey="info.privacyStatement" t={t}
						   components={[<Link target="_blank" href={t('info.privacyStatementLink')}>_</Link>]}/>
				</Typography>
			</Box>

			<Box>
				<Typography variant="h6">{t('info.iso27001Title')}</Typography>
				<Typography>{t('info.iso27001')}</Typography>
			</Box>

			<Box>
				<Typography variant="h6">{t('info.versionTitle')}</Typography>
				<Typography>{version}</Typography>
			</Box>

		</Paper>
	</AppContainer>;

}
