const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// company list overview
	overviewList: [],
	overviewCount: 0,

	// eligible parent companies
	eligibleParentList: [],

	// indicate that the action on the company was successful
	actionSuccessfully: false,

	// accounts linked to company
	accountOverviewList: [],
	accountOverviewCount: 0,
	accountActionSuccessfully: false,
	accountInvited: null,
	accountSettings: null,

	// settings
	settingsPendingChanges: false,

	// api settings linked to company
	companyApiKeysList: [],
	companyApiKeysCount: 0,
	companyApiKeysActionSuccessfully: false,

	// idp settings
	idpClientSecretList: [],
	idpClientSecretCount: 0,
	idpFlowSettings: null,
	idpFlowEditInfo: null,
	idpFlowList: [],

	// style settings
	styleSettings: null,

	// oidc login settings
	oidcInstanceOverviewList: [],
	oidcInstanceOverviewCount: 0,
	oidcInstanceSettings: null,
	oidcInstanceActionSuccessFully: false,
	createdOidcInstanceId: null,

	// stats
	statsConfig: null,
	stats: null,

	// action logs
	actionLogList: [],
	actionLogCount: 0,

	// sandbox account stuff
	sandboxAccountOverviewList: [],
	sandboxAccountOverviewCount: 0,
	sandboxAccountEntry: null
};

const company = (state = defaultState, action) => {
	switch (action.type) {
		case 'COMPANY_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'COMPANY_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'COMPANY_FETCH_OVERVIEW_LIST':
		case 'COMPANY_FETCH_ACTION_LOGS':
		case 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'COMPANY_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'COMPANY_FETCH_ACTION_LOGS_SUCCESS': {
			return {
				...state,
				busy: false,
				actionLogList: action.list,
				actionLogCount: action.count,
			}
		}

		case 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST_SUCCESS': {
			return {
				...state,
				busy: false,
				eligibleParentList: action.data,
			}
		}

		case 'COMPANY_CREATE':
		case 'COMPANY_UPDATE_ADMIN_SETTINGS':
		case 'COMPANY_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				actionSuccessfully: false
			}

		case 'COMPANY_CREATE_SUCCESS':
		case 'COMPANY_UPDATE_ADMIN_SETTINGS_SUCCESS':
		case 'COMPANY_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				actionSuccessfully: true
			}

		case 'COMPANY_FETCH_ADMIN_SETTINGS':
			return {
				...state,
				busy: true,
				adminSettings: null,
				serverError: null
			}

		case 'COMPANY_FETCH_ADMIN_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				adminSettings: action.data
			}

		case 'COMPANY_FETCH_ADMIN_COMPANY_APIKEYS_SUCCESS':
			return {
				...state,
				busy: false,
				adminApiSettings: action.data,
			}

		case 'COMPANY_UPDATE_ADMIN_COMPANY_APIKEYS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				adminApiSettings: action.data,
				actionSuccessfully: true
			}

		case 'COMPANY_CHANGE_ADMIN_SETTING': {
			const adminSettings = {
				...state.adminSettings
			};
			if (null === action.value) {
				delete adminSettings[action.key];
			} else {
				adminSettings[action.key] = action.value;
			}

			return {
				...state,
				adminSettings
			}
		}

		case 'COMPANY_ACCOUNT_FETCH_OVERVIEW_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'COMPANY_ACCOUNT_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				accountOverviewList: action.list,
				accountOverviewCount: action.count,
			}

		case 'COMPANY_ACCOUNT_INVITE':
			return {
				...state,
				busy: true,
				serverError: null,
				accountActionSuccessfully: false,
				accountInvited: null,
			}

		case 'COMPANY_ACCOUNT_INVITE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				accountActionSuccessfully: true,
				accountInvited: action.data,
			}

		case 'COMPANY_ACCOUNT_FETCH_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				accountSettings: null,
			}

		case 'COMPANY_ACCOUNT_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				accountSettings: action.data,
			}

		case 'COMPANY_ACCOUNT_CHANGE_SETTING': {
			const accountSettings = {
				...state.accountSettings
			};

			if (!!action.subkey) {
				accountSettings[action.key] = {
					...state.settings[action.key]
				};
				accountSettings[action.key][action.subkey] = action.value;
			} else {
				accountSettings[action.key] = action.value;
			}

			return {
				...state,
				accountSettings
			}
		}

		case 'COMPANY_ACCOUNT_UPDATE_SETTINGS':
		case 'COMPANY_ACCOUNT_DEACTIVATE':
		case 'COMPANY_ACCOUNT_REACTIVATE':
		case 'COMPANY_ACCOUNT_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				accountActionSuccessfully: false,
			}

		case 'COMPANY_ACCOUNT_UPDATE_SETTINGS_SUCCESS':
		case 'COMPANY_ACCOUNT_DEACTIVATE_SUCCESS':
		case 'COMPANY_ACCOUNT_REACTIVATE_SUCCESS':
		case 'COMPANY_ACCOUNT_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				accountActionSuccessfully: true,
			}

		case 'COMPANY_APIKEY_FETCH':
			return {
				...state,
				busy: true,
				serverError: null,
				companyApiKeysList: [],
				companyApiKeysCount: 0,
				settingsPendingChanges: false
			}

		case 'COMPANY_APIKEY_FETCH_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeysList: action.list,
				companyApiKeysCount: action.count,
			}
		case 'COMPANY_APIKEY_CREATE':
		case 'COMPANY_APIKEY_UPDATE':
		case 'COMPANY_APIKEY_REVOKE':
			return {
				...state,
				busy: true,
				serverError: null,
				companyApiKeysActionSuccessfully: false,
			}

		case 'COMPANY_APIKEY_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeysActionSuccessfully: true,
				companyApiKey: action.data
			}
		case 'COMPANY_APIKEY_UPDATE_SUCCESS':
		case 'COMPANY_APIKEY_REVOKE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeysActionSuccessfully: true,
			}
		case 'COMPANY_APIKEY_DIALOG_RESET':
			return {
				...state,
				companyApiKey: null
			}

		case 'COMPANY_FETCH_STATS_CONFIG':{
			return {
				...state,
				busy: true,
				serverError: null,
				statsConfig: null
			}
		}
		case 'COMPANY_FETCH_STATS_CONFIG_SUCCESS': {
			return {
				...state,
				busy: false,
				statsConfig: action.data,
			}
		}
		case 'COMPANY_FETCH_STATS': {
			return {
				...state,
				busy: true,
				serverError: null,
			}
		}
		case 'COMPANY_FETCH_STATS_SUCCESS': {
			return {
				...state,
				busy: false,
				stats: action.data,
			}
		}

		case 'COMPANY_EXPORT_STATS':
		case 'COMPANY_EXPORT_STATS_DETAILS': {
			return {
				...state,
				busy: true,
				serverError: false
			}
		}

		case 'COMPANY_IDP_FLOW_FETCH_EDIT_INFO':
			return {
				...state,
				busy: true,
				serverError: null,
				idpFlowEditInfo: null,
			}
		case 'COMPANY_IDP_FLOW_FETCH_EDIT_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				idpFlowEditInfo: action.data,
			}
		case 'COMPANY_IDP_FLOW_FETCH_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				idpFlowList: [],
				settingsPendingChanges: false
			}
		case 'COMPANY_IDP_FLOW_FETCH_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpFlowList: action.data,
			}
		case 'COMPANY_IDP_FLOW_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				idpFlowActionSuccessfully: false,
				createdFlowId: null
			}
		case 'COMPANY_IDP_FLOW_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpFlowActionSuccessfully: true,
				createdFlowId: action.createdFlowId
			}
		case 'COMPANY_IDP_FLOW_FETCH_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				idpFlowSettings: null,
			}
		case 'COMPANY_IDP_FLOW_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpFlowSettings: action.data,
			}
		case 'COMPANY_IDP_FLOW_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				idpFlowActionSuccessfully: false,
			}
		case 'COMPANY_IDP_FLOW_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpFlowActionSuccessfully: true
			}
		case 'COMPANY_IDP_FLOW_CHANGE_SETTING':
			const idpFlowSettings = {
				...state.idpFlowSettings
			};
			if (null === action.value) {
				delete idpFlowSettings[action.key];
			} else {
				idpFlowSettings[action.key] = action.value;
			}

			return {
				...state,
				idpFlowSettings
			}

		case 'COMPANY_IDP_CLIENT_SECRET_FETCH':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false
			}

		case 'COMPANY_IDP_CLIENT_SECRET_FETCH_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpClientSecretList: action.list,
				idpClientSecretCount: action.count,
			}
		case 'COMPANY_IDP_CLIENT_SECRET_CREATE':
		case 'COMPANY_IDP_CLIENT_SECRET_REVOKE':
		case 'COMPANY_IDP_CLIENT_SECRET_UPDATE':
			return {
				...state,
				busy: true,
				serverError: null,
				idpClientSecretActionSuccessfully: false,
			}
		case 'COMPANY_IDP_CLIENT_SECRET_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpClientSecretActionSuccessfully: true,
				idpClientSecret: action.data
			}
		case 'COMPANY_IDP_CLIENT_SECRET_REVOKE_SUCCESS':
		case 'COMPANY_IDP_CLIENT_SECRET_UPDATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				idpClientSecretActionSuccessfully: true,
			}
		case 'COMPANY_IDP_CLIENT_SECRET_DIALOG_RESET':
			return {
				...state,
				idpClientSecret: null
			}

		case 'COMPANY_FETCH_STYLE_SETTINGS':
		case 'COMPANY_UPDATE_STYLE_SETTINGS': {
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false
			}
		}
		case 'COMPANY_FETCH_STYLE_SETTINGS_SUCCESS': {
			return {
				...state,
				busy: false,
				styleSettings: action.data
			}
		}
		case 'COMPANY_CHANGE_STYLE_SETTINGS_ITEM': {
			const styleSettings = {
				...state.styleSettings
			};
			styleSettings[action.key] = action.value;
			return {
				...state,
				styleSettings,
				settingsPendingChanges: true
			}
		}

		case 'COMPANY_OIDC_INSTANCE_FETCH_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false
			}

		case 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				oidcInstanceSettings: {
					...state.oidcInstanceSettings,
					logoBase64: action.logoBase64
				}
			}

		case 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO':
			return {
				...state,
				serverError: null,
				oidcInstanceLogoUpdateError: null,
				busy: true
			}

		case 'COMPANY_OIDC_INSTANCE_FETCH_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				oidcInstanceOverviewList: action.data,
			}

		case 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS':
			return {
				...state,
				busy: true,
				oidcInstanceLogoUpdateError: null,
				oidcInstanceSettings: null
			}

		case 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				oidcInstanceSettings: action.data,
			}

		case 'OIDC_INSTANCE_CHANGE_SETTING':
			const oidcInstanceSettings = {
				...state.oidcInstanceSettings
			};
			if (null === action.value) {
				delete settings[action.key];
			} else {
				oidcInstanceSettings[action.key] = action.value;
			}

			return {
				...state,
				oidcInstanceSettings
			}

		case 'COMPANY_OIDC_INSTANCE_CREATE':
		case 'COMPANY_OIDC_INSTANCE_DELETE':
		case 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				oidcInstanceActionSuccessFully: false,
				createdOidcInstanceId: null
			}

		case 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS':
		case 'COMPANY_OIDC_INSTANCE_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				oidcInstanceLogoUpdateError: null,
				oidcInstanceActionSuccessFully: true
			}

		case 'COMPANY_OIDC_INSTANCE_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				oidcInstanceLogoUpdateError: null,
				oidcInstanceActionSuccessFully: true,
				createdOidcInstanceId: action.createdOidcInstanceId
			}

		case 'COMPANY_SANDBOX_ACCOUNT_FETCH_OVERVIEW_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false
			}

		case 'COMPANY_SANDBOX_ACCOUNT_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				sandboxAccountOverviewList: action.list,
				sandboxAccountOverviewCount: action.count
			}

		case 'COMPANY_SANDBOX_ACCOUNT_CREATE':
		case 'COMPANY_SANDBOX_ACCOUNT_IMPORT':
		case 'COMPANY_SANDBOX_ACCOUNT_FETCH':
		case 'COMPANY_SANDBOX_ACCOUNT_UPDATE':
		case 'COMPANY_SANDBOX_ACCOUNT_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				sandboxActionSuccessFully: false,
				sandboxAccountEntry: null,
			}

		case 'COMPANY_SANDBOX_ACCOUNT_CREATE_SUCCESS':
		case 'COMPANY_SANDBOX_ACCOUNT_IMPORT_SUCCESS':
		case 'COMPANY_SANDBOX_ACCOUNT_UPDATE_SUCCESS':
		case 'COMPANY_SANDBOX_ACCOUNT_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				sandboxActionSuccessFully: true,
			}

		case 'COMPANY_SANDBOX_ACCOUNT_FETCH_SUCCESS':
			return {
				...state,
				busy: false,
				sandboxAccountEntry: action.data,
			}

		default:
			return state;
	}
}

export default company;
