import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

export default (props) => {

	const {t} = useTranslation();

	return props.open && <Dialog open onClose={props.onClose} fullWidth maxWidth="sm">
		<DialogTitle>{t('attention')}</DialogTitle>
		<DialogContent>{props.labelContent}</DialogContent>
		<DialogActions>
			<Button
				onClick={props.onClose}
				disabled={props.busy}
				id="btn-pending-changes-cancel"
			>
				{t('back')}
			</Button>
			{!!props.onSaveAndConfirm && <Button
				variant="contained"
				onClick={props.onSaveAndConfirm}
				disabled={props.busy || props.saveDisabled}
				id="btn-pending-changes-save-confirm"
			>
				{props.labelSaveAndConfirm}
			</Button>}
			{!props.confirmDisabled && <Button
				variant="contained"
				onClick={props.onConfirm}
				disabled={props.busy}
				id="btn-pending-changes-confirm"
			>
				{props.labelConfirm}
			</Button>}
		</DialogActions>
	</Dialog>;

}