import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {format, parse} from "date-fns";
import TextsmsIcon from '@mui/icons-material/Textsms';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import ServerErrorComponent from "../common/ServerErrorComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Pagination,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";

const FILTER_MEDIA_LIST = (info, debug) => {
	return info?.docIdSessionFullInfo?.mediaCaptured
		?.filter(media => media.contentType?.startsWith('image/'))
		?.filter(media => debug || 'DEBUG' !== media.mediaType)
		;
}

const defaultState = {
	smsAttempt: 0,
	attributes: [],
	birthDate: null,
	documentIdMediaIndex: 0,
	showDebugMedia: false,
	popupImage: false,
	popupManualReviewFeedbackDialog: false,
	manualReviewFeedbackRequired: false,
	manualReviewFeedback: ''
}

class VisitInfoDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState, this.onVisitFetchInfo);
			this.timerId = setInterval(() => this.onVisitFetchInfoPolling(), 5000);
		}
		if (!this.props.open && prevProps && !!this.timerId) {
			clearInterval(this.timerId);
			this.timerId = 0;
		}
		if (this.props.open &&
			((!!this.props.visitInfo?.data && !prevProps.visitInfo?.data) ||
				(!!this.props.visitInfo?.docIdSessionFullInfo && !prevProps.visitInfo?.docIdSessionFullInfo) ||
				(this.props.visitInfo?.state !== prevProps.visitInfo?.state) ||
				(this.props.visitInfo?.docIdSessionState !== prevProps.visitInfo?.docIdSessionState))) {

			const birthDateRaw = this.props.visitInfo?.attributes?.filter(a => 'BIRTH_DATE' === a.type)?.[0]?.value;
			this.setState({
				attributes: this.props.visitInfo?.attributes || [],
				birthDate: !!birthDateRaw ? parse(birthDateRaw, 'yyyy-LL-dd', new Date()) : null
			});
		}
		if (this.props.open &&
			this.props.visitInfo?.docIdSessionFullInfo?.mediaCaptured?.length > 0 &&
			!prevProps.visitInfo?.docIdSessionFullInfo?.mediaCaptured?.length) {
			this.setState({
				documentIdMediaIndex: 0
			}, this.onVisitDocumentIdFetchMedia);
		}
	}

	componentWillUnmount() {
		if (!!this.timerId) {
			clearInterval(this.timerId);
		}
	}

	render() {
		const info = this.props.visitInfo;
		const documentIdMediaList = FILTER_MEDIA_LIST(info, this.state.showDebugMedia);
		const documentIdMedia = documentIdMediaList?.[this.state.documentIdMediaIndex];
		const documentIdReviewable = !!info?.docIdSessionFullInfo?.reviewable;

		const columns = [
			{
				field: 'step',
				headerName: this.props.t('visit.docIdValidationLogsStep'),
				editable: false,
				sortable: false,
				flex: 1
			},
			{
				field: 'action',
				headerName: this.props.t('visit.docIdValidationLogsAction'),
				editable: false,
				sortable: false,
				flex: 1
			},
			{
				field: 'result',
				headerName: this.props.t('visit.docIdValidationLogsResult'),
				editable: false,
				sortable: false,
				align: 'center',
				headerAlign: 'center',
				renderCell: (cellValues) => <>
					<Tooltip
						title={this.props.t('visit.docIdValidationLogsFactor') + ': ' + cellValues.row.factor.toFixed(2)}>
						{'FAILED' === cellValues.row.result ?
							<ErrorIcon color="error" fontSize="small"/> :
							('UNSURE' === cellValues.row.result ?
									<WarningIcon color="warning" fontSize="small"/> :
									<CheckIcon color="success" fontSize="small"/>
							)
						}
					</Tooltip>
				</>
			},
		];

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="xl"
		>
			<DialogTitle>
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<Box>{this.props.t('visit.infoDialogTitle')}</Box>
					{this.props.visitBusy && <CircularProgress size="1rem" sx={{ml: 1}}/>}
				</Box>
			</DialogTitle>
			<DialogContent>
				<ServerErrorComponent serverError={this.props.visitServerError}/>

				{!!info && <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
					<Box>
						<Typography>{this.props.t('visit.generalInfo')}</Typography>
						<Box sx={{display: 'flex', gap: 1, mt: 1, ml: 1}}>
							<TextField
								fullWidth
								size="small"
								value={!!info?.state ? this.props.t('visit.state_' + info.state) : ''}
								label={this.props.t('visit.state')}
								autoComplete="off"
								disabled
							/>
							<TextField
								fullWidth
								size="small"
								value={!!info?.data?.method ? this.props.t('visit.method_' + info.data.method) : ''}
								label={this.props.t('visit.methodUsed')}
								autoComplete="off"
								disabled
							/>
						</Box>
					</Box>

					{info.hasRemoteIdSession && <Box>
						<Typography>{this.props.t('visit.remoteIdInfo')}</Typography>
						<Box sx={{display: 'flex', gap: 1, mt: 1, ml: 1}}>
							<TextField
								fullWidth
								size="small"
								value={info?.phoneNumber}
								label={this.props.t('visit.phoneNumber')}
								autoComplete="off"
								disabled
							/>
							<TextField
								fullWidth
								size="small"
								value={info?.creatorEmail}
								label={this.props.t('visit.creatorEmail')}
								autoComplete="off"
								disabled
							/>
							{info.canResendRemoteIdSms && <Tooltip title={this.props.t('visit.remoteIdResendSms')}>
								<IconButton
									id="btn-visit-info-resend-sms"
									disabled={!info && this.props.visitBusy}
									onClick={this.onVisitRemoteIdSessionResendSms}
								>
									<TextsmsIcon/>
								</IconButton>
							</Tooltip>}
						</Box>
					</Box>}

					{!!info?.hasDocumentIdSession && !!info.docIdSessionFullInfo && <Box>
						<Box sx={{display: 'flex'}}>
							<Box
								sx={{flexGrow: 1}}><Typography>{this.props.t('visit.docIdInfo') + ': ' + info.docIdSessionFullInfo.reference}</Typography></Box>
							{!!info.docIdSessionState &&
								<Box>{this.props.t('visit.docIdSessionState_' + info.docIdSessionState) +
									(!!info?.docIdSessionFullInfo?.validationError ? ' (' + this.props.t('identification.failure.DOC_ID_' + info.docIdSessionFullInfo.validationError) + ')' : '')}</Box>}
						</Box>
						<Box sx={{display: 'flex', gap: 1, mt: 1, ml: 1}}>
							<Box sx={{width: '40%'}}>
								{documentIdMediaList?.length > 0 && <Box sx={{
									height: '360px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column'
								}}>
									<Typography variant="body2">{documentIdMedia?.mediaType}</Typography>
									{this.renderMedia(documentIdMedia)}
									{this.state.popupImage && this.renderMediaPopup(documentIdMedia)}
									<Pagination
										page={this.state.documentIdMediaIndex + 1}
										onChange={this.onDocumentIdMediaIndexChange}
										count={documentIdMediaList.length}
										sx={{mt: 1}}
									/>
								</Box>}
								{!documentIdMediaList?.length && <Box sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column',
									height: '100%'
								}}>
									<Typography variant="body2">{this.props.t('visit.docIdNoMedia')}</Typography>
								</Box>}
								{info?.docIdSessionFullInfo?.mediaCaptured?.some(media => 'DEBUG' === media.mediaType) &&
									<Box sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
										<FormControlLabel
											control={<Checkbox checked={this.state.showDebugMedia}
															   sx={{p: 0}}
															   onChange={this.onChangeShowDebugMedia}/>}
											label={this.props.t('visit.docIdShowDebugMedia')}
											sx={{m: 0}}
										/>
									</Box>}
							</Box>
							<Box sx={{width: '60%'}}>
								<Typography variant="body2">{this.props.t('visit.docIdValidationLogs')}</Typography>
								<Box sx={{flexGrow: 1, overflow: 'auto'}}>
									<DataGrid
										initialState={{
											pagination: { paginationModel: { pageSize: 25 } },
										}}

										autoHeight
										keepNonExistentRowsSelected
										disableColumnMenu

										columns={columns}
										slotProps={{
											baseCheckbox: {
												tabIndex: 0
											},
										}}

										pagination
										paginationMode="client"
										pageSizeOptions={[25]}

										rows={info.docIdSessionFullInfo?.logs || []}
										rowCount={info.docIdSessionFullInfo?.logs?.length || 0}

										density="compact"
									/>
								</Box>
							</Box>
						</Box>
					</Box>}

					{this.state.attributes.length > 0 && <Box>
						<Typography>{this.props.t('visit.results')}</Typography>
						<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, ml: 1}}>
							{this.state.attributes.map(attribute => <Box
								key={attribute.type}
								sx={{flexBasis: '30%', flexGrow: 1}}
							>
								{'BIRTH_DATE' === attribute.type && <DatePicker
									slotProps={{
										textField: {
											fullWidth: true,
											variant: 'outlined',
											size: 'small',
											autoComplete: 'off',
											label: this.props.t('visit.attribute_' + attribute.type),
											inputProps: {
												placeholder: 'yyyy-mm-dd'
											}
										}
									}}
									views={['year', 'month', 'day']}
									openTo="year"
									value={this.state.birthDate}
									onChange={(value) => this.onChangeAttribute(attribute.type, value)}
									format="yyyy-LL-dd"
									disabled={!documentIdReviewable}
								/>}
								{'GENDER' === attribute.type && <FormControl
									size="small"
									fullWidth
								>
									<InputLabel
										id="gender-label">{this.props.t('visit.attribute_' + attribute.type)}</InputLabel>
									<Select
										labelId="gender-label"
										value={attribute.value || ''}
										label={this.props.t('visit.attribute_' + attribute.type)}
										onChange={(e) => this.onChangeAttribute(attribute.type, e.target.value)}
										disabled={!documentIdReviewable}
									>
										{['male', 'female', ' x'].map(gender => <MenuItem key={gender}
																						  value={gender}>{gender}</MenuItem>)}
									</Select>
								</FormControl>}
								{'BIRTH_DATE' !== attribute.type && 'GENDER' !== attribute.type && <TextField
									fullWidth
									size="small"
									value={attribute.value || ''}
									label={this.props.t('visit.attribute_' + attribute.type)}
									autoComplete="off"
									onChange={e => this.onChangeAttribute(attribute.type, e.target.value)}
									disabled={!documentIdReviewable}
								/>}
							</Box>)}
						</Box>
					</Box>}
				</Box>}
			</DialogContent>
			<DialogActions>
				<Button
					id="btn-visit-info-close"
					onClick={this.onClose}
				>
					{this.props.t('close')}
				</Button>
				{!!info?.hasDocumentIdSession && !!info?.canReviewDocIdSession && <>
					<Button
						id="btn-visit-info-review-accept"
						variant="contained"
						onClick={this.onOpenManualReviewFeedbackDialog}
						disabled={this.props.visitBusy || !documentIdReviewable}
					>
						{this.props.t('visit.docIdManualReview')}
					</Button>

					<Dialog
						open={this.state.popupManualReviewFeedbackDialog}
						onClose={this.onCloseManualReviewFeedbackDialog}
						maxWidth="md"
						fullWidth
					>
						<DialogTitle>{this.props.t('visit.docIdManualReviewFeedbackTitle')}</DialogTitle>
						<DialogContent>
							<DialogContentText id="manual-review-feedback">
								<Typography>
									{this.props.t('visit.docIdManualReviewFeedbackHeader')}
								</Typography>
								<TextField
									variant="outlined"
									label={this.props.t('visit.docIdManualReviewFeedback')}
									onChange={this.onChangeManualReviewFeedback}
									value={this.state.manualReviewFeedback}
									autoComplete="off"
									fullWidth
									required={this.state.manualReviewFeedbackRequired}
									inputProps={{maxLength: 255}}
									sx={{mt: 2}}
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								id="btn-visit-info-review-accept"
								variant="contained"
								onClick={this.onVisitDocumentIdManualReviewAccept}
							>
								{this.props.t('visit.docIdManualReviewAccept')}
							</Button>
							<Button
								id="btn-visit-info-review-refuse"
								variant="contained"
								onClick={this.onVisitDocumentIdManualReviewRefuse}
								disabled={!this.state.manualReviewFeedback}
							>
								{this.props.t('visit.docIdManualReviewRefuse')}
							</Button>
						</DialogActions>
					</Dialog>
				</>}
			</DialogActions>
		</Dialog>
	}

	onImagePopupOpen = () => {
		this.setState({
			popupImage: true
		});
	}

	onImagePopupClose = () => {
		this.setState({
			popupImage: false
		});
	}

	renderMedia(media) {
		if (!this.props.visitDocumentIdMedia) {
			return <CircularProgress/>;
		}

		if (media.contentType?.startsWith('image')) {
			return <img
				src={this.props.visitDocumentIdMedia}
				alt='image'
				style={{maxWidth: '400px', maxHeight: '300px'}}
				onClick={this.onImagePopupOpen}
			/>;
		}
	}

	renderMediaPopup(media) {
		if (!this.props.visitDocumentIdMedia) {
			return <CircularProgress/>;
		}

		if (media.contentType?.startsWith('image')) {
			return <Modal
				open
				onClose={this.onImagePopupClose}
				style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<img
					src={this.props.visitDocumentIdMedia}
					alt='image'
					onClick={this.onImagePopupClose}
				/>
			</Modal>;
		}
	}

	onVisitFetchInfoPolling = () => {
		if ('IDENTIFIED' === this.props.visitInfo?.state || 'MANUAL_REVIEW_NEEDED' === this.props.visitInfo?.docIdSessionState) {
			clearInterval(this.timerId);
			this.timerId = 0;
			return;
		}
		if (!this.props.visitBusy) {
			this.props.onVisitFetchInfoPolling(this.props.id);
		}
	}

	onVisitFetchInfo = () => {
		this.props.onVisitFetchInfo(this.props.id);
	}

	onVisitRemoteIdSessionResendSms = () => {
		const attempt = this.state.smsAttempt;
		this.setState({
			smsAttempt: attempt + 1
		}, () => this.props.onVisitRemoteIdSessionResendSms(this.props.id, {attempt}));
	}

	onDocumentIdMediaIndexChange = (e, page) => {
		const documentIdMediaIndex = page - 1;
		if (this.state.documentIdMediaIndex !== documentIdMediaIndex) {
			this.setState({documentIdMediaIndex}, this.onVisitDocumentIdFetchMedia);
		}
	}

	onChangeShowDebugMedia = (e, checked) => {
		this.setState({showDebugMedia: checked, documentIdMediaIndex: 0}, this.onVisitDocumentIdFetchMedia);
	}

	onVisitDocumentIdFetchMedia = () => {
		const list = FILTER_MEDIA_LIST(this.props.visitInfo, this.state.showDebugMedia);
		if (!!list?.[this.state.documentIdMediaIndex]) {
			this.props.onVisitDocumentIdFetchMedia(this.props.id, list[this.state.documentIdMediaIndex].id);
		}
	}

	onChangeAttribute = (type, value) => {
		const newState = {}

		if ('BIRTH_DATE' === type) {
			newState.birthDate = value;
			value = (!!value && value instanceof Date && !isNaN(value)) ? format(value, 'yyyy-LL-dd') : value;
		}

		newState.attributes = this.state.attributes
			.map(a => a.type === type ? {...a, value} : a);

		this.setState(newState);
	}

	onChangeManualReviewFeedback = (e) => {
		this.setState({manualReviewFeedback: e.target.value === '' ? null : e.target.value});
	}

	onOpenManualReviewFeedbackDialog = () => {
		this.setState({popupManualReviewFeedbackDialog: true});
	}

	onCloseManualReviewFeedbackDialog = () => {
		this.setState({popupManualReviewFeedbackDialog: false});
	}

	onVisitDocumentIdManualReviewAccept = () => {
		this.onVisitDocumentIdManualReview(true);
		this.onCloseManualReviewFeedbackDialog();
	}

	onVisitDocumentIdManualReviewRefuse = () => {
		this.onVisitDocumentIdManualReview(false);
		this.onCloseManualReviewFeedbackDialog();
	}

	onVisitDocumentIdManualReview = (accepted) => {
		this.props.onVisitDocumentIdManualReview(this.props.id, {
			accepted,
			feedback: this.state.manualReviewFeedback,
			attributes: this.state.attributes
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			visitBusy: state.visit.busy,
			visitServerError: state.visit.serverError,
			visitInfo: state.visit.info,
			visitDocumentIdMedia: state.visit.documentIdMedia
		}
	},
	dispatch => {
		return {
			onVisitFetchInfo: (id) => {
				dispatch({
					type: 'VISIT_FETCH_INFO',
					id
				});
			},
			onVisitFetchInfoPolling: (id) => {
				dispatch({
					type: 'VISIT_FETCH_INFO_POLLING',
					id
				});
			},
			onVisitRemoteIdSessionResendSms: (id, request) => {
				dispatch({
					type: 'VISIT_REMOTE_ID_SESSION_RESEND_SMS',
					id,
					request
				});
			},
			onVisitDocumentIdFetchMedia: (id, mediaId) => {
				dispatch({
					type: 'VISIT_DOCUMENT_ID_FETCH_MEDIA',
					id,
					mediaId
				});
			},
			onVisitDocumentIdManualReview: (id, request) => {
				dispatch({
					type: 'VISIT_DOCUMENT_ID_MANUAL_REVIEW',
					id,
					request
				});
			}
		}
	}
)(VisitInfoDialog));
