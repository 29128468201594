import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Routes} from "react-router";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {LocalizationProvider} from "@mui/x-date-pickers";
import AdminSectionComponent from "./admin/AdminSectionComponent";
import CompanyAccountOverviewComponent from "./company/CompanyAccountOverviewComponent";
import SessionProfileComponent from "./session/SessionProfileComponent";
import LoginPasswordComponent from "./session/LoginComponent";
import IndexComponent from "./misc/IndexComponent";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {MAP_DATE_FNS_LOCALE} from "./common/Constants";
import i18n from "i18next";
import {enUS, frFR, nlNL} from "@mui/material/locale";
import {enUS as enUSDataGrid, frFR as frFRDataGrid, nlNL as nlNLDataGrid} from "@mui/x-data-grid-pro/locales";
import {Redirect, withRouter} from "./common/RouterHelper";
import ResetPasswordUpdateComponent from "./session/ResetPasswordUpdateComponent";
import ResetPasswordRequestComponent from "./session/ResetPasswordRequestComponent";
import CompanyInsightsComponent from "./company/CompanyInsightsComponent";
import CompanySettingsComponent from "./company/CompanySettingsComponent";
import InfoComponent from "./misc/InfoComponent";
import LoadingComponent from "./common/LoadingComponent";
import VisitOverviewComponent from "./visit/VisitOverviewComponent";
import FolderOverviewComponent from "./folder/FolderOverviewComponent";
import SessionSwitchCompanyComponent from "./session/SessionSwitchCompanyComponent";

class App extends Component {

	constructor(props, context) {
		super(props, context);

		// check if a CSRF token was transferred from another (sub)domain
		if (!!this.props.csrfToken) {
			localStorage.setItem('TOKEN_ACCOUNT_CSRF', this.props.csrfToken)
		}

		const postLoginPath = localStorage.getItem('post-login-path');
		if (!!postLoginPath && window.location.search.indexOf('state') > 0) {
			// we have a 'state' param in the URL, we can be pretty sure this is a callback after authentication
			localStorage.removeItem('post-login-path');

			this.postLogin = true;
			this.props.onSessionOidcLoginCallback(postLoginPath);
		} else {
			this.postLogin = false;
		}
	}

	render() {
		if (!!this.props.csrfToken) {
			return <Redirect to={this.props.path} />
		}
		if (this.postLogin) {
			return <LoadingComponent/>
		}

		const sessionInfo = this.props.sessionInfo;
		const lang = sessionInfo?.language?.toLowerCase() || i18n.resolvedLanguage;
		const theme = createTheme({
			palette: {
				primary: {
					main: '#ff5000',
					contrastText: '#FFFFFF'
				},
				secondary: {
					main: '#000000',
					contrastText: '#FFFFFF'
				},
				appbar: {
					main: '#FFFFFF',
					contrastText: '#000000'
				},
				background: {
					default: '#FFFFFF',
				}
			},
			typography: {
				fontFamily: 'Lato,"Trebuchet MS",Arial,Helvetica,sans-serif'
			},
			components: {
				MuiDataGrid: {
					styleOverrides: {
						root: {
							"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
								outline: "none"
							}
						},
					},
					...(lang === "nl" && nlNLDataGrid.components.MuiDataGrid),
					...(lang === "fr" && frFRDataGrid.components.MuiDataGrid),
					...(lang === "en" && enUSDataGrid.components.MuiDataGrid),
				},
				...(lang === "nl" && nlNL.components),
				...(lang === "fr" && frFR.components),
				...(lang === "en" && enUS.components),
			}
		});

		return <ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={MAP_DATE_FNS_LOCALE(sessionInfo)}>
				<CssBaseline/>
				<Routes>
					{/* admin */}
					<Route path='/admin' element={<AdminSectionComponent/>}/>

					{/* company related stuff */}
					<Route path='/company/account-overview' element={<CompanyAccountOverviewComponent/>}/>
					<Route path='/company/settings' element={<CompanySettingsComponent/>}/>
					<Route path='/company/insights' element={<CompanyInsightsComponent/>}/>
					<Route path='/folder/overview' element={<FolderOverviewComponent/>}/>

					{/* session stuff */}
					<Route path='/profile' element={<SessionProfileComponent/>}/>
					<Route path='/switch-company' element={<SessionSwitchCompanyComponent/>}/>

					{/* login */}
					<Route path='/login/:data?' element={<LoginPasswordComponent/>}/>
					<Route path='/reset-password-request' element={<ResetPasswordRequestComponent/>}/>
					<Route path='/reset-password-update/:payload' element={<ResetPasswordUpdateComponent/>}/>

					{/* misc */}
					<Route path='/info' element={<InfoComponent/>}/>

					{/* visit */}
					<Route path="/visit/overview" exact element={<VisitOverviewComponent/>} />
					<Route path='/' element={<IndexComponent />}/>
				</Routes>
			</LocalizationProvider>
		</ThemeProvider>;
	}
}

export default withRouter(connect(
	state => {
		let csrfToken;
		if (state.router?.location?.search?.startsWith('?csrf=')) {
			csrfToken = state.router.location.search.substring(6);
		}
		return {
			sessionInfo: state.session.info,
			csrfToken,
			path: state.router.location.pathname
		}
	},
	dispatch => {
		return {
			onSessionOidcLoginCallback: (loginSavedPath) => {
				dispatch({
					type: 'SESSION_OIDC_LOGIN_CALLBACK',
					loginSavedPath
				})
			}
		}
	}
)(App));
