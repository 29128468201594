import React, {Component} from "react";
import {Box, Button} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from "@mui/icons-material/Sync";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import {DataGridPro as DataGrid, GridActionsCellItem} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import CompanyCreationDialog from "../company/CompanyCreationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import ConfirmationDialog from "../common/ConfirmationDialog";
import CompanyAdminSettingsDialog from "./CompanyAdminSettingsDialog";
import CompanyChildCreationDialog from "./CompanyChildCreationDialog";

class CompanyOverviewComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [],

			// dialogs
			creationDialogOpen: false,
			childCreationDialogOpen: false,
			updateDialogOpen: false,
			deleteDialogOpen: false,
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onCompanyFetchOverviewList());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyActionSuccessfully && !prevProps.companyActionSuccessfully) {
			this.onCompanyFetchOverviewList();
		}
	}

	render() {
		const busy = this.props.companyBusy &&
			!this.state.creationDialogOpen &&
			!this.state.childCreationDialogOpen &&
			!this.state.updateDialogOpen;

		const dataGridColumns = [
			{
				field: 'type',
				headerName: '',
				editable: false,
				align: 'center',
				width: 50,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (cellValues) => (!!cellValues.row.parentCompanyName && 	<DomainAddIcon titleAccess={this.props.t('company.child')} fontSize="small"/>)
			},
			{
				field: 'name',
				headerName: this.props.t('company.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'parentCompanyName',
				headerName: this.props.t('company.parentCompanyName'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				align: 'right',
				width: 120,
				getActions: (params) => [
					<GridActionsCellItem
						title={this.props.t('company.settings')}
						label={this.props.t('company.settings')}
						icon={<SettingsIcon color="primary" fontSize="small"/>}
						onClick={() => {
							this.setState({activeCompanyId: params.id}, this.onUpdateDialogOpen);
						}}
					/>,
					((disabled) =>
						<GridActionsCellItem
							disabled={disabled}
							title={this.props.t('company.switch')}
							label={this.props.t('company.switch')}
							icon={<SyncIcon color={disabled ? 'disabled' : 'primary'} fontSize="small"/>}
							onClick={() => {
								this.props.onCompanySwitch({companyId: params.id});
							}}
						/>
					)(this.props.sessionInfo.companyId === params.id),
					<GridActionsCellItem
						title={this.props.t('company.delete')}
						label={this.props.t('company.delete')}
						icon={<DeleteIcon color="primary" fontSize="small"/>}
						onClick={() => {
							this.setState({activeCompanyId: params.id}, this.onDeleteDialogOpen);
						}}
					/>
				]
			},
		];

		return <Box sx={{mt: 2}}>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1, gap: 1}}>
				{this.props.sessionInfo.applicationAdmin &&
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={busy}
							id="btn-company-create"
					>
						{this.props.t('company.create')}
					</Button>
				}
				<Button variant="contained"
						onClick={this.onChildCreationDialogOpen}
						startIcon={<AddIcon/>}
						sx={{mr: 1}}
						disabled={busy}
						id="btn-company-create-child"
				>
					{this.props.t('company.createChild')}
				</Button>
			</Box>
			<DataGrid autoHeight
					  disableColumnSelector
					  columns={dataGridColumns}
					  slots={{toolbar: FlexibleToolbar}}
					  slotProps={{
						  toolbar: {
							  filterId: 'input-company-overview-search-text',
							  filterValue: this.state.filterValue,
							  onChangeFilterValue: this.onFilterValueChange,
						  }
					  }}

					  loading={busy}

					  pagination
					  paginationMode="server"
					  paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					  onPaginationModelChange={this.onPaginationModelChange}
					  pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					  sortingMode="server"
					  sortModel={this.state.sortModel}
					  onSortModelChange={this.onSortModelChange}

					  disableColumnFilter
					  disableRowSelectionOnClick

					  rows={this.props.companyOverviewList}
					  rowCount={this.props.companyOverviewCount}
					  density="compact"/>

			<CompanyCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onCompanyCreate}/>

			<CompanyChildCreationDialog
				open={this.state.childCreationDialogOpen}
				onClose={this.onChildCreationDialogClose}
				onCreate={this.onCompanyCreate}/>

			<CompanyAdminSettingsDialog
				companyId={this.state.activeCompanyId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onUpdateAdminSettings={this.onCompanyUpdateAdminSettings}/>

			<ConfirmationDialog
				title={this.props.t('company.delete')}
				confirm={this.props.t('company.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onCompanyDelete}/>
		</Box>
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onChildCreationDialogOpen = () => {
		this.setState({childCreationDialogOpen: true});
	}

	onChildCreationDialogClose = () => {
		this.setState({childCreationDialogOpen: false});
	}

	onCompanyCreate = (request) => {
		this.setState({
			creationDialogOpen: false,
			childCreationDialogOpen: false,
		}, () => this.props.onCompanyCreate(request));
	}

	onUpdateDialogOpen = () => {
		this.setState({updateDialogOpen: true});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onCompanyUpdateAdminSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onCompanyUpdateAdminSettings(this.state.activeCompanyId, settings));
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onCompanyDelete = () => {
		this.setState({deleteDialogOpen: false}, () => this.props.onCompanyDelete(this.state.activeCompanyId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanyFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchOverviewList);
	}

	onCompanyFetchOverviewList = () => {
		this.props.onCompanyFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyOverviewList: state.company.overviewList,
			companyOverviewCount: state.company.overviewCount,
			companyActionSuccessfully: state.company.actionSuccessfully,
		}
	},
	dispatch => {
		return {
			onCompanyFetchOverviewList: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onCompanyCreate: (request) => {
				dispatch({
					type: 'COMPANY_CREATE',
					request
				});
			},
			onCompanyUpdateAdminSettings: (companyId, settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_ADMIN_SETTINGS',
					companyId,
					settings
				});
			},
			onCompanyDelete: (companyId) => {
				dispatch({
					type: 'COMPANY_DELETE',
					companyId
				});
			},
			onCompanySwitch: (request) => {
				dispatch({
					type: 'SESSION_SWITCH_COMPANY',
					request
				});
			}
		}
	}
)(CompanyOverviewComponent));
