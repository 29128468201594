import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {withTranslation} from "react-i18next";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";

class SessionProfileGeneralComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {}
	}

	componentDidMount() {
		this.props.onSessionFetchProfile();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		const profile = this.props.sessionProfile;

		if (!profile) {
			return <LoadingComponent/>;
		}

		return <>
			<Typography sx={{fontWeight: 700}}>{this.props.t('session.profileGeneralHeader')}</Typography>
			<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
				<FormControl disabled={this.props.sessionBusy} sx={{flex: '1 0 200px', maxWidth: '250px'}}>
					<InputLabel
						id="label-rows-per-page">{this.props.t('session.profileDefaultRowCount')}</InputLabel>
					<Select
						labelId="label-rows-per-page"
						value={ROWS_PER_PAGE_SELECT(profile.defaultRowCount)}
						label={this.props.t('session.profileDefaultRowCount')}
						onChange={this.onChangeDefaultRowCount}
					>
						{ROWS_PER_PAGE_OPTIONS.map(option =>
							<MenuItem key={option} value={option}>{option}</MenuItem>
						)}
					</Select>
				</FormControl>
				<FormControl sx={{flex: '1 0 200px', maxWidth: '250px'}}>
					<InputLabel
						id="profile-language">{this.props.t('session.profileLanguage')}</InputLabel>
					<Select
						labelId="profile-language"
						label={this.props.t('session.profileLanguage')}
						value={profile.language}
						onChange={this.onChangeLanguage}
					>
						{['EN', 'NL', 'FR'].map(lang =>
							<MenuItem key={lang}
									  value={lang}>{this.props.t('lang', {lng: lang.toLowerCase()})}
							</MenuItem>)}
					</Select>
				</FormControl>
				<ServerErrorComponent serverError={this.props.sessionServerError} />
			</Box>
			<Box sx={{mt: 2}}>
				<Button
					variant="contained"
					onClick={this.onSave}
					startIcon={<SaveIcon/>}
					id="btn-settings-save"
					disabled={this.props.sessionBusy}
				>
					{this.props.t('save')}
				</Button>
			</Box>
		</>
	}

	onChangeDefaultRowCount = (e) => {
		this.props.onSessionChangeProfileData('defaultRowCount', e.target.value);
	}

	onChangeLanguage = (e) => {
		this.props.onSessionChangeProfileData('language', e.target.value);
	}

	onSave = () => {
		this.props.onSessionUpdateProfile(this.props.sessionProfile);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionBusy: state.session.busy,
			sessionInfo: state.session.info,
			sessionProfile: state.session.profile,
			sessionServerError: state.session.serverError
		}
	},
	dispatch => {
		return {
			onSessionFetchProfile: () => {
				dispatch({
					type: 'SESSION_FETCH_PROFILE'
				})
			},
			onSessionChangeProfileData: (key, value) => {
				dispatch({
					type: 'SESSION_CHANGE_PROFILE_DATA',
					key: key,
					value: value
				});
			},
			onSessionUpdateProfile: (request) => {
				dispatch({
					type: 'SESSION_UPDATE_PROFILE',
					request
				})
			},
		}
	}
)(SessionProfileGeneralComponent));
