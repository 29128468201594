import React, {Component, Fragment, useEffect} from "react";
import {connect} from "react-redux";
import i18n from "i18next";
import {withTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {
	AppBar as MuiAppBar,
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Drawer,
	Grid,
	IconButton,
	LinearProgress,
	Link,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faCircleUser, faGear, faRightFromBracket, faUserTie} from '@fortawesome/free-solid-svg-icons'
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Engineering from "@mui/icons-material/Engineering";
import DrawerMenuItem from "./DrawerMenuItem";
import PersonIcon from '@mui/icons-material/Person';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import FolderIcon from '@mui/icons-material/Folder';
import {ReactComponent as Logo} from "../../img/logo.svg";
import {LANGUAGES} from "./Constants";
import GlobalSnackbar from "./GlobalSnackbar";
import LoadingComponent from "./LoadingComponent";
import {withRouter} from "./RouterHelper";
import PendingChangesDialog from "./PendingChangesDialog";

const drawerWidth = 62;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
	({theme, open}) => ({
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: '0px',
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: `${drawerWidth}px`,
		}),
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column'
	}),
);

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const StyledLinearProgress = styled(LinearProgress)(() => ({
	height: 4,
	backgroundColor: '#f5f7ff',
}));

const Footer = styled(Box)(({theme}) => ({
	marginTop: 'auto',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	marginLeft: theme.spacing(3),
	marginRight: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
}));

const InternalTokenExpirationHandler = ({tokenExpiryValidator, tokenExpiry, tokenExpiredAction}) => {
	useEffect(() => {
		window.addEventListener("focus", onFocus);
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	const onFocus = () => {
		// check clientside if the session did not yet expire, to prevent the user from working locally on an outdated session
		if (tokenExpiryValidator(tokenExpiry)) {
			tokenExpiredAction();
		}
	};

	return <></>;
};

class AppContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			langMenuAnchorEl: null,
			pendingChangesDialogOpen: false,
			pendingChangesPath: null,
			pendingChangesCustom: false,
			pendingChangesSaved: false,
		};
	}

	componentDidMount() {
		if (this.props.needsSession) {
			// if there is custom session creator, then call it everytime
			if (!this.props.sessionCreated) {
				this.props.onSessionCheck();
			} else {
				if (!!this.props.onSessionCreated) {
					this.props.onSessionCreated(this.props.sessionInfo);
				}
				this.props.onSessionRefreshInfo();
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.needsSession) {
			if (this.props.sessionCreated && !prevProps.sessionCreated) {
				if (!!this.props.onSessionCreated) {
					this.props.onSessionCreated(this.props.sessionInfo);
				}
			}
			if (!this.props.sessionCreated && prevProps.sessionCreated) {
				this.props.onSessionCheck();
			}
		}

		// save the pending changes before navigating
		if (this.state.pendingChangesSaved) {
			if (prevProps.hasPendingChanges && !this.props.hasPendingChanges) {
				// saved successfully
				this.setState({pendingChangesSaved: false}, this.onPendingChangesConfirm);
			} else if (!prevProps.saveHasError && this.props.saveHasError) {
				// error occurred
				this.setState({pendingChangesSaved: false}, this.onPendingChangesCloseDialog);
			}
		}

		// there is a custom navigation requested inside the page
		if (this.props.customNavigation) {
			if (this.props.hasPendingChanges) {
				// + pending changes => open the dialog if it is not done already
				if (!this.state.pendingChangesDialogOpen) {
					this.setState({pendingChangesDialogOpen: true, pendingChangesCustom: true});
				}
			} else {
				// + no changes, we can directly grant the request
				this.props.onCustomNavigationComplete();
			}
		}
	}

	render() {
		const langMenuOpen = null !== this.state.langMenuAnchorEl;
		const langCurrentResolved = i18n.resolvedLanguage;
		const sessionInfo = this.props.sessionInfo;
		const termsAndConditionsDialogOpen = !!this.props.needsSession && !!sessionInfo && !(sessionInfo?.termsAndConditionsAccepted);

		if (this.props.needsSession && !sessionInfo) {
			return <LoadingComponent/>;
		}

		return <>
			<AppBar position="fixed" open={this.props.menuOpen && this.props.withMenu} elevation={0} color="appbar">
				<Toolbar variant="dense"
						 sx={{alignItems: 'flex-start ', pt: '0px', pl: '0px!important', pr: '0px!important', height: '60px', boxShadow: '1px 2px 1px #DDDDDD'}}>
					<Grid container>
						{this.props.withProgress &&
							<Grid item xs={12}>
								<StyledLinearProgress variant="determinate" value={this.props.progress}/>
							</Grid>
						}
						<Grid item xs={12}
							  sx={{display: 'flex', alignItems: 'center', pl: 2}}>
							{this.props.withMenu && !this.props.menuOpen &&
								<IconButton
									color="inherit"
									onClick={this.props.onAppMenuOpen}
									edge="start"
									sx={{mr: 2}}>
									<MenuIcon/>
								</IconButton>
							}

							<Box sx={{flexGrow: 1, pt: '4px', pb: '2px'}}>
								<RouterLink to="/">
									<Logo style={{width: 100, height: 50}}/>
								</RouterLink>
							</Box>

							{!this.props.needsSession && <Box sx={{mr: 2}}>
								<IconButton
									onClick={this.onLangMenuOpen}
									aria-controls={langMenuOpen ? 'lang-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={langMenuOpen ? 'true' : undefined}>
									<Avatar>{langCurrentResolved.toUpperCase()}</Avatar>
								</IconButton>
								<Menu
									anchorEl={this.state.langMenuAnchorEl}
									open={langMenuOpen}
									id="lang-menu"
									onClose={this.onLangMenuClose}
									onClick={this.onLangMenuClose}>
									{
										LANGUAGES.map(lang => lang.toLowerCase()).map(code =>
											<MenuItem key={code}
													  onClick={() => this.onChangeLanguage(code)}>
												{this.props.t('lang', {lng: code})}
											</MenuItem>)
									}
									<Divider/>
									<MenuItem key="auto"
											  onClick={() => this.onChangeLanguage()}
									>
										{this.props.t('app.autoDetect')}
									</MenuItem>
								</Menu>
							</Box>}

							{this.props.needsSession && !!sessionInfo && !!sessionInfo.companyId && <Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									...(sessionInfo.inMultipleCompanies && {cursor: 'pointer'}),
								}}
								onClick={this.onNavigateToCompanySwitch}
							>
								<FontAwesomeIcon icon={faUserTie} color="lightgrey" size="2x"/>
								<Box sx={{ml: 1, mr: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', lineHeight: 1}}>
									<Box
										sx={{color: 'lightgrey'}}>{sessionInfo.companyAdmin ? this.props.t('app.admin') : this.props.t('app.user')}</Box>
									<Box sx={{fontWeight: 700}}>{sessionInfo.companyName}</Box>
								</Box>
							</Box>}

							{this.props.needsSession && !!sessionInfo && <Box
								sx={{display: 'flex', cursor: 'pointer', alignItems: 'center'}}
								onClick={this.onNavigateToProfile}
							>
								<FontAwesomeIcon icon={faCircleUser} color="lightgrey" size="2x" />
								<Box sx={{ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', lineHeight: 1, }}>
									<Box sx={{
										color: 'lightgrey',
										display: {xs: 'none', sm: 'block'}
									}}>{this.props.t('app.loggedInAs')}</Box>
									<Box sx={{fontWeight: 700}}>{sessionInfo.email}</Box>
								</Box>
							</Box>}

							{this.props.needsSession && !!sessionInfo && <Box
								sx={{display: 'flex', lineHeight: 1, fontSize: '1em', cursor: 'pointer', ml: 3, mr: 2}}
								onClick={() => this.onTryNavigate('/logout')}
							>
								<FontAwesomeIcon icon={faRightFromBracket} size="2x"/>
							</Box>}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						backgroundColor: 'black',
						border: 0,
					},
				}}
				variant="persistent"
				anchor="left"
				open={this.props.menuOpen && this.props.withMenu}>

				<DrawerHeader sx={{justifyContent: 'center'}}>
					<IconButton onClick={this.props.onAppMenuClose} sx={{color: '#808080'}}>
						<ChevronLeftIcon/>
					</IconButton>
				</DrawerHeader>

				<Divider/>

				{!!sessionInfo && <Box sx={{
					display: 'flex',
					flexDirection: 'column',
					overflowX: 'hidden',
					color: '#808080',
					mt: 'auto',
					mb: 'auto',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						display: 'none'
					}
				}}>
					{[{
						icon: <Engineering/>,
						text: 'menu.admin',
						route: '/admin',
						id: 'nav-menu-admin',
						width: drawerWidth,
						visible: !!sessionInfo?.adminSectionAccessible
					},{
						icon: <PersonIcon/>,
						text: 'menu.accounts',
						route: '/company/account-overview',
						id: 'nav-menu-account-overview',
						width: drawerWidth,
						visible: !!sessionInfo?.companyAccountOverviewAccessible
					}, {
						icon: <FontAwesomeIcon icon={faGear} size="lg"/>,
						text: 'menu.settings',
						route: '/company/settings',
						id: 'nav-menu-company-settings',
						width: drawerWidth,
						visible: !!sessionInfo?.companySettingsAccessible
					}, {
						icon: <FolderIcon/>,
						text: 'menu.folders',
						route: '/folder/overview',
						id: 'nav-menu-folder-overview',
						width: drawerWidth,
						visible: !!sessionInfo?.companyFolderOverviewAccessible
					}, {
						icon: <SensorOccupiedIcon/>,
						text: 'menu.visits',
						route: '/visit/overview',
						id: 'nav-menu-visit-overview',
						width: drawerWidth,
						visible: !!sessionInfo?.visitsAccessible
					}, {
						icon: <FontAwesomeIcon icon={faChartLine} size="lg"/>,
						text: 'menu.insights',
						route: '/company/insights',
						id: 'nav-menu-insights',
						width: drawerWidth,
						visible: !!sessionInfo?.companyStatisticsAccessible
					}, {
						icon: <HelpOutlineIcon/>,
						text: 'menu.info',
						route: '/info',
						id: 'nav-menu-info',
						width: drawerWidth,
						visible: true
					}].filter(e => e.visible).map(e => <DrawerMenuItem
						key={e.id}
						id={e.id}
						icon={e.icon}
						text={this.props.t(e.text)}
						badge={e.badge}
						route={e.route}
						selectionRoutes={e.selectionRoutes}
						onClick={() => this.onTryNavigate(e.route)}
					/>)}
				</Box>}
			</Drawer>

			<GlobalSnackbar/>

			{!!this.props.sessionInfo?.internalTokenExpiry && <InternalTokenExpirationHandler
				tokenExpiryValidator={this.isExpired}
				tokenExpiry={this.props.sessionInfo?.internalTokenExpiry}
				tokenExpiredAction={this.onTokenExpired}
			/>}

			<Main
				open={this.props.menuOpen && this.props.withMenu}
				sx={{
					backgroundColor: '#f2f2f2'
				}}
			>
				<DrawerHeader/>
				<Box sx={{padding: 2, ...(this.props.withCenteredChildren ? {mt: 'auto', mb: 'auto'} : {})}}>
					{this.props.children}
				</Box>
				<Footer>
					<Typography variant="caption">
						{this.props.t('app.footerAllRightsReserved')}&nbsp;
						<a href="https://smartsolutions.dioss.com/" target="_blank">Dioss Smart
							Solutions</a>&nbsp;&#169;{(new Date().getFullYear())}
					</Typography>
				</Footer>
			</Main>

			<Dialog
				maxWidth="sm"
				fullWidth
				open={termsAndConditionsDialogOpen}
				onClose={this.onDialogNoClose}
			>
				<DialogTitle>{this.props.t('app.termsAndConditionsTitle')}</DialogTitle>
				<DialogContent>
					<Box>{this.props.t('app.termsAndConditionsAgree')}</Box>
					<Box>
						<ul>
							{['app.termsAndConditionsGeneral', 'app.termsAndConditionsPrivacy', 'app.termsAndConditionsCookie'].map((key, index) => <li key={index}>
								{this.props.t(key).split('$').map((v, index) => {
									if (v.indexOf('link') === 0) {
										const elements = v.split(';');
										return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
									}
									return;
								})}
							</li>)}
						</ul>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={this.props.onSessionAcceptTerms} autoFocus>
						{this.props.t('app.termsAndConditionAccept')}
					</Button>
				</DialogActions>
			</Dialog>

			<PendingChangesDialog
				open={this.state.pendingChangesDialogOpen}
				onClose={this.onPendingChangesCloseDialog}
				busy={this.props.saveIsBusy}
				saveDisabled={this.props.saveDisabled}
				confirmDisabled={this.props.confirmDisabled}
				onConfirm={this.onPendingChangesConfirm}
				onSaveAndConfirm={!!this.props.onSave ? this.onPendingChangesSaveAndConfirm : undefined}
				labelContent={!!this.props.pendingChangesLabel ? this.props.pendingChangesLabel : this.props.t('session.pendingChanges')}
				labelConfirm={this.props.t('session.pendingChangesLeave')}
				labelSaveAndConfirm={!!this.props.pendingChangesSaveAndLeaveLabel ? this.props.pendingChangesSaveAndLeaveLabel : this.props.t('session.pendingChangesSaveAndLeave')}
			/>
		</>
	}

	onTryNavigate = (path) => {
		const currentPath = this.props.history?.location?.pathname || '';
		if (path === currentPath) {
			return;
		}

		if (this.props.hasPendingChanges) {
			this.setState({pendingChangesDialogOpen: true, pendingChangesPath: path, pendingChangesCustom: false});
		} else {
			this.onNavigate(path);
		}
	}

	onNavigate = (path) => {
		const sessionExpired = this.props.sessionInfo?.internalTokenExpiry && this.isExpired(this.props.sessionInfo?.internalTokenExpiry);

		if (sessionExpired) {
			this.onTokenExpired();
		} else if ('/logout' === path) { // don't replace this with an actual route
			this.props.onSessionLogout();
		} else {
			this.props.router.navigate(path);
		}
	}

	onLangMenuOpen = (event) => {
		this.setState({langMenuAnchorEl: event.currentTarget});
	}

	onLangMenuClose = () => {
		this.setState({langMenuAnchorEl: null});
	}

	onChangeLanguage = (code) => {
		// update the language in the browser
		if (!code) {
			localStorage.removeItem('i18nextLng');
		}
		i18n.changeLanguage(code);
	}

	onNavigateToCompanySwitch = () => {
		if (!this.props.sessionInfo?.inMultipleCompanies) {
			return;
		}

		this.onTryNavigate('/switch-company');
	}

	onNavigateToProfile = () => {
		this.onTryNavigate('/profile');
	}

	isExpired = (expiryDate) => {
		return (Date.parse(expiryDate) - Date.now()) < 0;
	}

	onTokenExpired = () => {
		this.props.onSessionDestroy();
		this.props.router.navigate('/login')
	}

	onDialogNoClose = (e, reason) => {}

	onPendingChangesCloseDialog = (e, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		if (this.state.pendingChangesCustom) {
			this.props.onCustomNavigationCanceled();
		}
		this.setState({pendingChangesDialogOpen: false});
	}

	onPendingChangesConfirm = () => {
		const {pendingChangesPath, pendingChangesCustom} = this.state;
		if (pendingChangesCustom) {
			this.props.onCustomNavigationComplete();
			this.setState({pendingChangesDialogOpen: false});
		} else {
			this.setState({pendingChangesDialogOpen: false}, () => this.onNavigate(pendingChangesPath));
		}
	}

	onPendingChangesSaveAndConfirm = () => {
		this.setState({pendingChangesSaved: true}, this.props.onSave);
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			menuOpen: state.app.menuOpen,

			sessionBusy: state.session.busy,
			sessionInfo: state.session.info,
			sessionCreated: state.session.created,
		}
	},
	dispatch => {
		return {
			onAppMenuOpen: () => {
				dispatch({
					type: 'APP_MENU_OPEN',
				})
			},
			onAppMenuClose: () => {
				dispatch({
					type: 'APP_MENU_CLOSE',
				})
			},
			onSessionCheck: () => {
				dispatch({
					type: 'SESSION_CHECK'
				})
			},
			onSessionRefreshInfo: () => {
				dispatch({
					type: 'SESSION_REFRESH_INFO'
				})
			},
			onSessionLogout: () => {
				dispatch({
					type: 'SESSION_LOGOUT',
				})
			},
			onSessionAcceptTerms: () => {
				dispatch({
					type: 'SESSION_ACCEPT_TERMS',
				})
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			}
		}
	}
)(AppContainer)));
