import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	name: '',
	contactEmail: '',
}

class CompanyCreationDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.create')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.nameHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.name')}
								required
								value={this.state.name}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.contactEmailHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.contactEmail')}
								value={this.state.contactEmail}
								onChange={this.onChangeContactEmail}
								autoComplete="off"
								fullWidth
							/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!this.canContinue()} onClick={this.onCreate}
						id="btn-create-confirm">
					{this.props.t('company.create')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	canContinue = () => {
		const {name} = this.state;
		return !!name.trim();
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeContactEmail = (e) => {
		this.setState({contactEmail: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		const {name, contactEmail} = this.state;
		this.props.onCreate({name, contactEmail})
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(CompanyCreationDialog));
