import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
	Box,
	Button,
	Paper,
	Step,
	Stepper,
	StepLabel,
	StepContent,
	TextField,
	Typography,
	Select,
	MenuItem
} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import SaveIcon from "@mui/icons-material/Save";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ReactComponent as Logo} from "../../img/logo.svg";

const FONTS = [
	'DEFAULT',
	'PROXIMA_NOVA',
	'TITILLIUM',
	'OPEN_SANS',
];

class CompanyStyleSettingsComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.onCompanyFetchStyleSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		if (!this.props.companyStyleSettings) {
			return <LoadingComponent/>;
		}

		const styleSettings = this.props.companyStyleSettings;

		const fontFamily = (() => {
			switch (styleSettings.font) {
				case 'DEFAULT':
				default:
					return 'Lato, sans-serif'
				case 'PROXIMA_NOVA':
					return 'Proxima Nova, sans-serif'
				case 'TITILLIUM':
					return 'Titillium, sans-serif';
				case 'OPEN_SANS':
					return 'OpenSans, sans-serif';
			}
		})();

		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('company.styleSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>

			<Box sx={{mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap'}}>
				{/* settings */}
				<Box>
					<Typography sx={{fontWeight: 700}}>{this.props.t('company.styleSettingsGlobal')}</Typography>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.globalBackgroundColor}
							onChange={e => this.onChangeColor('globalBackgroundColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsGlobalBackgroundColor')}</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.toolbarBackgroundColor}
							onChange={e => this.onChangeColor('toolbarBackgroundColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsToolbarBackgroundColor')}</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.toolbarTextColor}
							onChange={e => this.onChangeColor('toolbarTextColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsToolbarTextColor')}</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.cardBackgroundColor}
							onChange={e => this.onChangeColor('cardBackgroundColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsCardBackgroundColor')}</Typography>
					</Box>

					<Typography sx={{fontWeight: 700, mt: 1}}>{this.props.t('company.styleSettingsPrimary')}</Typography>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.primaryMainColor}
							onChange={e => this.onChangeColor('primaryMainColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsMainColor')}</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.primaryContrastColor}
							onChange={e => this.onChangeColor('primaryContrastColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsContrastColor')}</Typography>
					</Box>

					<Typography sx={{fontWeight: 700, mt: 1}}>{this.props.t('company.styleSettingsSecondary')}</Typography>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.secondaryMainColor}
							onChange={e => this.onChangeColor('secondaryMainColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsMainColor')}</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
						<TextField
							variant="outlined"
							size="small"
							type="color"
							value={'#' + styleSettings.secondaryContrastColor}
							onChange={e => this.onChangeColor('secondaryContrastColor', e)}
							sx={{width: 50}}
							disabled={this.props.companyBusy}
							autoComplete="off"
						/>
						<Typography>{this.props.t('company.styleSettingsContrastColor')}</Typography>
					</Box>

					<Typography sx={{fontWeight: 700, mt: 1}}>{this.props.t('company.styleSettingsFont')}</Typography>
					<Select
						size="small"
						fullWidth
						value={styleSettings.font || ''}
						onChange={this.onChangeFont}
					>
						{FONTS.map(font => <MenuItem key={font} value={font}>{font}</MenuItem>)}
					</Select>

					<Typography sx={{fontWeight: 700, mt: 1}}>{this.props.t('company.styleSettingsLogo')}</Typography>
					<Box>
						{!!styleSettings.logoBase64 &&
							<Button variant="outlined" onClick={this.onChangeLogo} sx={{p: 1}} id="btn-company-style-logo-change">
								<img
									style={{width: '90px', maxHeight: '90px'}}
									src={'data:image/png;base64,' + styleSettings.logoBase64}
								/>
							</Button>}
						{!styleSettings.logoBase64 &&
							<Button color="secondary" variant="contained" onClick={this.onChangeLogo}
									disabled={this.props.companyBusy} id="btn-company-style-logo-change">
								{this.props.t('company.styleSettingsLogoChoose')}
							</Button>}
						<input type="file" id="file" accept=".png, .jpeg, .jpg" style={{display: 'none'}}></input>
					</Box>

				</Box>
				{/* preview */}
				<Box sx={{flexGrow: 1, border: '1px solid #AAAAAA', minHeight: '450px', fontFamily}}>

					{/* background */}
					<Box
						sx={{
							backgroundColor: '#' + styleSettings.globalBackgroundColor,
							width: '100%',
							height: '100%',
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						{/* toolbar */}
						<Box
							sx={{
								backgroundColor: '#' + styleSettings.toolbarBackgroundColor,
								width: '100%',
								minHeight: '45px',
								display: 'flex',
								justifyContent: 'space-between',
								pl: 1,
								pr: 1,
								alignItems: 'center'
							}}
						>
							{!!styleSettings.logoBase64 ?
								<img
									style={{maxHeight: '34px'}}
									src={'data:image/png;base64,' + styleSettings.logoBase64}
								/>
								:
								<Logo height="38px" />
							}

							<Box sx={{color: '#' + styleSettings.toolbarTextColor, fontSize: '0.8rem'}}>EXAMPLE</Box>
						</Box>

						{/* body */}
						<Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
							<Paper variant="outlined" sx={{p: 2, minWidth: '50%', backgroundColor: '#' + styleSettings.cardBackgroundColor}}>
								<Stepper activeStep={-1} orientation="vertical" sx={{width: '100%', fontSize: '0.8rem'}}>
									<Step active sx={{
										'.MuiSvgIcon-root': {
											color: '#' + styleSettings.primaryMainColor + ' !important'
										},
										'.MuiStepIcon-text': {
											fontFamily: 'inherit',
										}
									}}>
										<StepLabel sx={{
											'.MuiStepLabel-label': {
												fontFamily: 'inherit'
											}
										}}>
											LABEL
										</StepLabel>
										<StepContent>Lorem ipsum dolor sit amet, consectetur adipiscing elit</StepContent>
									</Step>
								</Stepper>
								<Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1}}>
									<Button variant="contained" size="small" sx={{backgroundColor: '#' + styleSettings.secondaryMainColor, color: '#' + styleSettings.secondaryContrastColor, fontFamily: 'inherit'}}>Secondary action</Button>
									<Button variant="contained" size="small" sx={{backgroundColor: '#' + styleSettings.primaryMainColor, color: '#' + styleSettings.primaryContrastColor, fontFamily: 'inherit'}}>Primary action</Button>
								</Box>
							</Paper>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Button variant="contained" onClick={this.onSave} id="btn-settings-save"
						startIcon={<SaveIcon/>}>{this.props.t('save')}</Button>
			</Box>
		</Box>
	}

	onChangeColor = (key, e) => {
		const value = e.target.value.replace('#', '');
		this.props.onCompanyChangeStyleSettingsItem(key, value);
	}

	onChangeFont = (e) => {
		this.props.onCompanyChangeStyleSettingsItem('font', e.target.value);
	}

	onChangeLogo = () => {
		if (this.props.companyBusy) return;

		const fileEl = document.getElementById("file");
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index1 = result.indexOf(';');
					const index2 = result.indexOf(',');
					if (index1 > 0 && index2 > index1) {
						this.props.onCompanyChangeStyleSettingsItem('logoMimeType', result.substring(5, index1));
						this.props.onCompanyChangeStyleSettingsItem('logoBase64', result.substring(index2 + 1));
					}
				}
				reader.readAsDataURL(file);
			}
		};
		fileEl.click();
	}

	onSave = () => {
		this.props.onCompanyUpdateStyleSettings(this.props.companyStyleSettings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyStyleSettings: state.company.styleSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchStyleSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_STYLE_SETTINGS'
				});
			},
			onCompanyUpdateStyleSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_STYLE_SETTINGS',
					settings
				});
			},
			onCompanyChangeStyleSettingsItem: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_STYLE_SETTINGS_ITEM',
					key,
					value
				});
			}
		}
	}
)(CompanyStyleSettingsComponent));
