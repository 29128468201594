import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";

const defaultState = {
	name: '',
	parentCompanyId: '',
	eligibleParentList: null
}

class CompanyChildCreationDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState, this.props.onFetchEligibleParents);
		} else if (!this.state.parentCompanyId && !!this.props.eligibleParentList && this.props.eligibleParentList.length === 1) {
			// auto select first entry if there are no other options
			this.setState({parentCompanyId: this.props.eligibleParentList[0].id});
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.createChild')}</DialogTitle>
			{!this.props.eligibleParentList && <DialogContent><LoadingComponent /></DialogContent>}
			{this.props.eligibleParentList &&
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('company.nameHeader')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('company.name')}
									required
									value={this.state.name}
									onChange={this.onChangeName}
									autoComplete="off"
									fullWidth
									autoFocus
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.createParentHeader')}</StepLabel>
							<StepContent>
								<FormControl fullWidth={true} required>
									<InputLabel
										id="parent-company-label">{this.props.t('company.createParent')}</InputLabel>
									<Select
										labelId="parent-company-label"
										value={this.state.parentCompanyId}
										label={this.props.t('company.createParent')}
										onChange={this.onChangeParent}
									>
										{(this.props.eligibleParentList || []).map(parent => <MenuItem key={parent.id}
																									   value={parent.id}>{parent.name}</MenuItem>)}
									</Select>
								</FormControl>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>
			}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							disabled={!this.state.name.trim() || !this.state.parentCompanyId}
							onClick={this.onCreate}
							id="btn-create-confirm"
					>
						{this.props.t('company.create')}
					</Button>
				</DialogActions>
		</Dialog>
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeParent = (e) => {
		this.setState({parentCompanyId: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.state.name.trim() && !!this.state.parentCompanyId) {
			this.onCreate();
		}
	}

	onCreate = () => {
		this.props.onCreate({name: this.state.name, parentCompanyId: this.state.parentCompanyId})
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			eligibleParentList: state.company.eligibleParentList,
		}
	},
	dispatch => {
		return {
			onFetchEligibleParents: () => {
				dispatch({
					type: 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST',
				});
			}

		}
	}
)(CompanyChildCreationDialog));
